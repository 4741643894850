<script setup lang="ts">
import type { Token } from '@/storeModules/game'
import { autoLoginGoatFromGameAccount, callBacks, closeLoginDialog, getLoginGaContext, logInGameKey, showHowMode } from '@/components/layout/LoginDialog/LoginDialog'
import { ElMessage } from 'element-plus'
import { jwtDecode } from 'jwt-decode'

interface Props {
  deepLinkUrl: string
  isOpenFromDeepLink?: boolean
  buyMode?: boolean
}
const props = defineProps<Props>()
const { gaLoginMethods, gaLogin } = gaUtils
const isSupportClipboard = ref(false)
const loading = {
  ref: ref('')
}
const { buyOffer, checkBuyOfferWithApi } = gamesUtils
const gameSettings = computed(() => {
  return gamesUtils.getGameSettings(buyOffer.value?.gameKey || gamesUtils.routeGame.value?.gameKey || logInGameKey.value)
})
function onSubmit(data: FormTypes.SubmitForm) {
  if (gameSettings.value?.gameKey) {
    utilsPlugin.getApp()?.$store?.commit?.('game/logOut', gameSettings.value.gameKey)
    loading.ref.value = 'logInDialog'
    const gameKey = gameSettings.value.gameKey
    gamesUtils
      .apiPost(gameKey, 'auth/token/', { playerID: data.form.accountID, token: data.form.accountToken }, false)
      .then(async ({ data }) => {
        if (data.token) {
          let decodedJwt = null
          try {
            decodedJwt = jwtDecode(data.token)
          } catch (err) {
            console?.error?.('not decoded jwt', err, data.token)
          }

          const tokenData = {
            ...{ id: '', name: '', token: '', valid: '', __token: '' },
            ...{
              token: decodedJwt ? data.token : '',
              ...(decodedJwt || {}),
              __token: data.token
            }
          }
          let isConnectedGoatAccount = false
          if (gameSettings.value?.gameKey) {
            if (tokenData.id) {
              isConnectedGoatAccount = await autoLoginGoatFromGameAccount(tokenData.__token)
              if (!isConnectedGoatAccount) {
                gamesUtils.setGameToken(gameSettings.value.gameKey, tokenData as Token)
              }
            } else {
              gamesUtils.setGameToken(gameSettings.value.gameKey, tokenData as Token)
            }
          }
          if (!gamesUtils.routeGame.value?.gameKey) {
            gamesUtils.triggerApi.callTrigger()
          }
          if ((decodedJwt && tokenData.id) || isConnectedGoatAccount) {
            if (!props.buyMode) {
              ElMessage({
                message: 'Logged in',
                type: 'success',
                duration: 3000,
                offset: 10
              })
            }

            const gaLoginContext = getLoginGaContext()
            gaLoginContext.method =
                  gaLoginContext?.method === gaLoginMethods.deepLink
                    ? `${gaLoginMethods.deepLink}, ${gaLoginMethods.gameToken}${isConnectedGoatAccount ? `,${gaLoginMethods.goat}` : ''}`
                    : `${gaLoginMethods.gameToken}${isConnectedGoatAccount ? `,${gaLoginMethods.goat}` : ''}`
            gaLogin(gaLoginContext)
            closeLoginDialog()
            checkBuyOfferWithApi(loading.ref)
          } else {
            gamesUtils.showError({ message: 'Login failed', appendTo: '#login-gs-game-form', offset: 10 })
          }
        } else {
          gamesUtils.showError({ message: 'Login failed', appendTo: '#login-gs-game-form', offset: 10 })
        }
      })
      .catch((error) => {
        if (error?.response?.data?.ban) {
          utilsPlugin
            .getApp()
            ?.$store
            ?.commit?.('game/setLogBan', [gameKey, String(error?.response?.data?.ban)])
        }
        gamesUtils.catchNotifyError({ appendTo: '#login-gs-game-form', offset: 10 })(error)
      })
      .then(() => {
        loading.ref.value = ''
      })
  }
}
async function onPaste(ev: ClipboardEvent, form: FormTypes.SubmitForm['form'], formRef: FormTypes.SubmitForm['formRefer'] | null) {
  let pastedData = ''
  if (ev.clipboardData) {
    pastedData = ev.clipboardData?.getData?.('Text') || ''
  }
  if (!pastedData.length && isSupportClipboard.value) {
    pastedData = await navigator?.clipboard?.readText?.()
  }
  if (pastedData?.length) {
    const checkVal = pastedData.split(' ')
    if (checkVal.length === 2) {
      ev.stopPropagation()
      ev.preventDefault()
      form.accountID = Number.isNaN(Number.parseInt(checkVal[0])) ? checkVal[0] : Number.parseInt(checkVal[0])
      form.accountToken = checkVal[1]
      formRef?.validate?.()
    }
  }
}
const formInterface = ref<FormTypes.SubmitForm | null>(null)
async function paste() {
  if (utilsPlugin.getApp()?.$route.query?.debug) {
    // eslint-disable-next-line no-alert
    alert('Paste data method')
  }
  let paste = ''
  try {
    paste = await navigator?.clipboard?.readText?.()
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('error', err)
  }
  if (paste?.length && formInterface.value) {
    const pasteSplit = paste.split(' ')
    if (pasteSplit.length === 2) {
      formInterface.value.form.accountID = Number.isNaN(Number.parseInt(pasteSplit[0])) ? pasteSplit[0] : Number.parseInt(pasteSplit[0])
      formInterface.value.form.accountToken = pasteSplit[1]
      formInterface.value.formRefer?.validate?.()
    }
  }
}
onMounted(() => {
  isSupportClipboard.value = typeof navigator?.clipboard?.readText === 'function'
})
function onInitForm(iForm: FormTypes.SubmitForm) {
  formInterface.value = iForm
  callBacks.paste = paste
}
</script>

<template>
  <div class="login-form-wrapper mx-auto max-w-2xl big:max-w-screen-xl">
    <div
      class="login-form flex flex-col items-center"
    >
      <div class="mx-auto w-full max-w-xl">
        <div class="mx-auto w-full max-w-md pt-1">
          <div class="px-2 mb-0">
            <div
              class="h-12 rounded-lg flex justify-between items-center text-white bg-gs-main-color overflow-hidden"
            >
              <div class="flex justify-between items-center text-base font-semibold w-full uppercase">
                <div>
                  <img
                    alt="game icon"
                    :src="gameSettings?.icon"
                    class="size-12 inline-block mr-1.5"
                  >
                  {{ gameSettings?.name }}
                </div>
              </div>
            </div>
            <div class="text-right">
              <el-button
                link
                size="large"
                class="underline underline-offset-2 text-xs hover:text-neutral-700"
                @click.stop="showHowMode = true"
              >
                see more
              </el-button>
            </div>
          </div>
          <GsFormComponent
            id="login-gs-game-form"
            class="w-full relative"
            size="large"
            hide-required-asterisk
            @submit="onSubmit"
            @init="onInitForm"
          >
            <template #default="{ submit, form, formRefer }">
              <GsFieldTextNumber
                v-focus
                api-field-name="accountID"
                placeholder="Game PlayerID"
                :label="!!String(form?.accountID ?? '').length ? 'Game PlayerID' : false"
                class="mt-1 big:mt-1.5 text-lg"
                required
                clearable
                :rules="[
                  { required: true, message: 'Please enter game PlayerID', trigger: 'blur' },
                  { type: 'number', message: 'PlayerID must be a number' },
                  { type: 'number', min: 1, message: 'Only positive numbers', trigger: 'change' }
                ]"
                @paste.stop="onPaste($event, form, formRefer || null)"
                @keyup.enter.stop.prevent="submit"
              />
              <GsFieldTextTrim
                api-field-name="accountToken"
                placeholder="Game Token"
                :label="!!form?.accountToken?.length"
                class="mt-1 big:mt-1.5 text-lg"
                clearable
                required
                :rules="[{ required: true, message: 'Please enter game Game Token', trigger: 'blur' }]"
                @paste.stop="onPaste($event, form, formRefer || null)"
                @keyup.enter.stop.prevent="submit"
              />
              <div
                v-if="isSupportClipboard"
                class="text-right -mt-5 mb-3 ml-auto mr-2"
              >
                <el-button
                  link
                  class="text-xs hover:text-neutral-700"
                  @click="paste"
                >
                  Paste the data
                </el-button>
              </div>
              <GsFormFieldsGroup
                justify="center"
              >
                <el-button
                  color="#2c7c8b"
                  class="text-white rounded-lg uppercase text-lg w-full max-w-44 font-semibold gs-loading"
                  size="large"
                  :loading="loading.ref.value === 'logInDialog'"
                  @click="submit"
                >
                  login
                </el-button>
              </GsFormFieldsGroup>
            </template>
          </GsFormComponent>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
</style>
