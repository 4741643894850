<script setup lang="ts">
import { accordionModel } from '@/components/layout/LoginDialog/LoginDialog'

interface ExtendedIdConfiguration extends google.accounts.id.IdConfiguration {
  ux_mode?: 'popup' | 'redirect'
  login_uri?: string
  locale?: string
}

interface Props {
  clientID?: string
  btnText?: 'signin_with' | 'signup_with'
  theme?: string
  size?: string
  shape?: string
  locale?: string
  logoAligment?: string
  callBack: (token: string) => void
}
const props = withDefaults(defineProps<Props>(), {
  clientID: '108055860030-6bi4udbj1v8pbi2tda2if1pkusc2j7up.apps.googleusercontent.com',
  btnText: 'signin_with',
  theme: 'filled_blue',
  size: 'large',
  shape: 'rectangular',
  locale: 'en_US',
  logoAligment: 'left'
})
const { screens } = tools
function handleGoogleCredentialResponse(response: google.accounts.id.CredentialResponse) {
  props.callBack(response.credential)
}
const isInit = ref(false)
const googleButtonContainer = useTemplateRef('google-button-container')
const scale = ref('')
function scaleBtn() {
  setTimeout(() => {
    const btnWidth = googleButtonContainer.value?.clientWidth || 0
    if (btnWidth) {
      const targetWidth = screens.value.big.alLeast ? 270 : 240
      scale.value = `transform: scale(${(targetWidth / btnWidth).toFixed(1)})`
    }
    isInit.value = true
  }, 800)
}
function initButton() {
  scaleBtn()
}
watch(() => accordionModel.value, () => {
  scaleBtn()
})
onMounted(() => {
  (window as any).handleGoogleCredentialResponse = handleGoogleCredentialResponse
  const optionsBtn = {
    theme: props.theme,
    size: props.size,
    text: props.btnText,
    type: 'standard',
    locale: props.locale,
    shape: props.shape,
    logo_aligment: props.logoAligment
  }
  const config: ExtendedIdConfiguration = {
    client_id: props.clientID,
    callback: handleGoogleCredentialResponse,
    ux_mode: 'popup',
    auto_prompt: false
  }
  if (!window.google) {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.defer = true
    script.onload = () => {
      window.google.accounts.id.initialize(config)
      if (googleButtonContainer.value) {
        window.google.accounts.id.renderButton(googleButtonContainer.value, optionsBtn as any)
        initButton()
      }
    }
    document.head.appendChild(script)
  } else {
    if (googleButtonContainer.value) {
      window.google.accounts.id.initialize(config)
      window.google.accounts.id.renderButton(googleButtonContainer.value, optionsBtn as any)
      initButton()
    }
  }
})
</script>

<template>
  <div
    :id="btnText"
    ref="google-button-container"
    :key="btnText"
    class="transition-opacity min-h-14"
    :class="{ 'opacity-0': !isInit }"
    :style="[scale]"
  />
</template>

<style scoped lang="postcss">

</style>
