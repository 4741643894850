import type { FormInstance } from 'element-plus'

function checkNumberValidator() {}
function mustBeValueValidator(value: any): (_rule: unknown, fieldValue: any) => boolean {
  return (_rule: unknown, fieldValue: any) => {
    return value === fieldValue
  }
}
function passwordValidator() {
  return {
    validator: (_rule: unknown, value: string) => {
      if (!value?.length) {
        return Promise.reject(new Error('required'))
      }
      const errorMsg: string[] = []
      if (value.length < 6) {
        errorMsg.push('6 characters')
      }
      if (!/[a-z]/.test(value)) {
        errorMsg.push('a lowercase letter')
      }
      if (!/[A-Z]/.test(value)) {
        errorMsg.push('a uppercase letter')
      }
      if (!/\d/.test(value)) {
        errorMsg.push('a number')
      }
      if (!/[^a-z0-9\s]/i.test(value)) {
        errorMsg.push('a special character')
      }
      if (errorMsg.length > 0) {
        return Promise.reject(new Error(`Must contain: ${errorMsg.join(', ')}`))
      }
      return Promise.resolve()
    },
    trigger: 'change'
  }
}
function confirmPasswordValidator(form: Record<string, any>, passwordFieldName = 'password') {
  return {
    validator: (_rule: unknown, value: string) => {
      if (!value?.length) {
        return Promise.reject(new Error('required'))
      }
      if (value !== form[passwordFieldName]) {
        return Promise.reject(new Error('Passwords do not match'))
      }
      return Promise.resolve()
    },
    trigger: 'change'
  }
}
function onChangePassword(form: Record<string, any>, formInstance: FormInstance | undefined, confirmPasswordFieldName = 'confirmPassword'): void {
  if (form?.[confirmPasswordFieldName]?.length) {
    formInstance?.validateField(confirmPasswordFieldName).catch(() => {})
  }
}
export {
  checkNumberValidator,
  confirmPasswordValidator,
  mustBeValueValidator,
  onChangePassword,
  passwordValidator
}
