<script setup lang="ts">
import Logo from '@/assets/logo-green.svg'
import { optGtag, showBar, showManager } from '@/components/layout/ManageCookies/ManageCookiesCompose'
import { bootstrap } from 'vue-gtag'

const { nextLoopEvent } = utilsPlugin

interface ManageCookiesSetting {
  showBar?: boolean
  optional: Record<string, boolean>
}

const manageCookies = ref<ManageCookiesSetting>({ showBar: false, optional: { ga: false } })

onMounted(() => {
  const settings: ManageCookiesSetting = JSON.parse(`${localStorage?.getItem?.('manageCookies')}`) || {}
  if (settings?.optional) {
    manageCookies.value.optional = { ...manageCookies.value.optional, ...settings.optional }
  }
  if (settings?.showBar !== false || settings?.optional?.ga === undefined) {
    nextLoopEvent(400).then(() => {
      showBar.value = true
    })
  }

  const isSsr = import.meta.env.SSR
  const isDevMode = import.meta.env.MODE === 'development'
  if (!isSsr && !isDevMode && settings?.optional?.ga) {
    bootstrap().then(() => {
      optGtag.value = true
    })
  }
})

function close() {
  showManager.value = false
  localStorage?.setItem?.('manageCookies', JSON.stringify(manageCookies.value))
  if (manageCookies.value?.optional?.ga) {
    if (!optGtag.value) {
      bootstrap().then(() => {
        optGtag.value = true
      })
    } else {
      utilsPlugin.getApp()?.$gtag?.optIn()
    }
  } else {
    utilsPlugin.getApp()?.$gtag?.optOut()
  }
}
function saveSelection(all = false) {
  if (all) {
    Object.keys(manageCookies.value.optional).forEach((key) => {
      manageCookies.value.optional[key] = true
    })
  }
  showBar.value = manageCookies.value.showBar = false
  close()
}
watch(
  () => showManager.value,
  () => {
    if (showManager.value) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }
)
</script>

<template>
  <transition name="accordion-cookies">
    <div
      v-if="showBar"
      id="manage-cookies-bar"
      class="fixed bottom-0 left-0 right-0 grid bg-black/90 text-white text-base transition-all duration-500 ease-in z-50"
    >
      <div class="overflow-hidden">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-[15%] gap-y-10 px-4 py-10 sm:p-[5vw] items-center">
          <div class="text-center flex justify-center md:justify-end">
            <div class="inline-block">
              <div>We use cookies to ensure you get the best experience on our website.</div>
              <router-link
                to="/privacy-policy"
                class="border-b-2 border-gs-bg px-0.5 hover:text-gs-main-color cursor-pointer whitespace-nowrap"
              >
                Learn more <icon-ify
                  icon="mingcute:arrows-right-fill"
                  class="w-2.5 inline -ml-1"
                />
              </router-link>
            </div>
          </div>
          <div class="flex flex-col sm:flex-row sm:flex-wrap justify-center md:justify-start gap-x-8 gap-y-4">
            <div class="inline-flex justify-center">
              <button
                class="bg-gs-main-color px-4 py-3 rounded font-semibold active:opacity-90"
                @click="saveSelection(true)"
              >
                Accept Cookies
              </button>
            </div>
            <button
              class="text-gs-main-color font-semibold active:opacity-90"
              @click="saveSelection(false)"
            >
              Don't Accept
            </button>
            <button
              class="text-gs-main-color font-semibold active:opacity-90"
              @click="showManager = true"
            >
              Manage Cookies
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="toggle">
    <div
      v-if="showManager"
      class="manager fixed bg-black/90 overflow-hidden z-50"
    >
      <div class="h-dvh flex justify-center items-center big:items-start big:mt-[15vh] p-4">
        <div class="overflow-y-auto max-h-full min-w-10 max-w-full">
          <div class="rounded-2xl bg-white max-w-xl overflow-hidden border-2 border-cyan-800 border-t-8 border-b-8">
            <div class="border-b border-b-cyan-800 px-4 pt-4 pb-2 big:pl-8 relative">
              <div class="text-cyan-700 pb-1 flex justify-between items-center big:items-start">
                <div>
                  <Logo class="w-8 big:w-10" />
                </div>
                <button
                  class="text-2xl transition hover:rotate-90"
                  @click="showManager = false"
                >
                  <icon-ify
                    icon="material-symbols:close"
                    class=""
                  />
                </button>
              </div>
            </div>
            <div class="p-4 big:p-8">
              <h1 class="uppercase font-bold text-xl tracking-wide">
                Cookies settings
              </h1>
              <div class="mt-5 text-sm">
                <div class="flex justify-between items-center">
                  <h2 class="font-semibold text-lg">
                    Necessary Cookies
                  </h2>
                  <div class="text-gs-main-color">
                    Always Active
                  </div>
                </div>
                <div class="mb-2 text-xs">
                  <div class="max-w-[80%]">
                    These cookies are necessary for the website to function. They enable you to use the website features and cannot
                    be switched off in our systems.
                  </div>
                </div>
              </div>
              <div class="mt-5 text-sm">
                <div class="flex justify-between items-center">
                  <h2 class="font-semibold text-lg">
                    Optional Cookies
                  </h2>
                  <div class="text-gs-main-color">
                    Optional
                  </div>
                </div>
                <div class="mb-2 pt-2">
                  <div class="flex justify-between items-start gap-2">
                    <div class="grow-1 max-w-[80%]">
                      <div class="font-medium text-gs-bg">
                        Analytical Cookies
                      </div>
                      <div class="text-xs">
                        Analytical cookies measure the number of visits and gather information about traffic sources. We use them to improve the functionality of the store. They help us understand which pages are the most popular or how you navigate through our website. Not consenting to their use does not affect your ability to use the site
                      </div>
                    </div>
                    <div>
                      <el-switch
                        v-model="manageCookies.optional.ga"
                        inline-prompt
                        active-text="&nbsp;ON&nbsp;"
                        inactive-text="&nbsp;OFF&nbsp;"
                        style="--el-switch-on-color: #2d7c8a; --el-switch-off-color: #9ea0a1"
                        size="large"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 text-sm">
                <router-link
                  to="/privacy-policy"
                  class="relative text-cyan-700 tracking-wide underline-offset-2 px-0.5 hover:text-cyan-800 active:opacity-90"
                  @click="showManager = false"
                >
                  read more <icon-ify
                    icon="mingcute:arrows-right-fill"
                    class="w-2.5 inline -ml-1"
                  />
                </router-link>
              </div>
            </div>
            <div class="border-t border-t-cyan-800 px-4 pt-4 pb-3">
              <div class="flex justify-end">
                <div class="inline-flex justify-center text-white gap-3">
                  <button
                    class="border border-gs-main-color text-gs-main-color px-2.5 py-1.5 rounded font-medium hover:text-gs-main-color hover:bg-gs-main-color/5 active:opacity-90"
                    @click="saveSelection(false)"
                  >
                    Confirm your choice
                  </button>
                  <button
                    class="bg-gs-main-color px-2.5 py-1.5 rounded font-medium hover:text-cyan-50 active:opacity-90"
                    @click="saveSelection(true)"
                  >
                    Accept all
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="postcss">
.accordion-cookies-enter-active,
.accordion-cookies-leave-active {
  grid-template-rows: 1fr;
}

.accordion-cookies-enter-from,
.accordion-cookies-leave-to {
  grid-template-rows: 0fr;
}

.manager {
  height: 100vh;
  width: 100vw;
  top: 0;
}

.toggle-enter-active {
  animation: toggle-in ease-in 0.5s;
  > * {
    opacity: 1;
  }
}
.toggle-leave-active {
  animation: toggle-in ease-in 0.5s reverse;
  > * {
    transition-property: opacity;
    transition-duration: 150ms;
    opacity: 0;
  }
}
@keyframes toggle-in {
  0% {
    height: 0;
    top: 50%;
  }
  100% {
    height: 100dvh;
    top: 0;
  }
}
a.relative {
  &:before {
    content: ' ';
    display: block;
    background: currentColor;
    height: 2px;
    width: 0;
    transition: width;
    transition-timing-function: ease-in;
    transition-duration: 300ms;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
  &:hover {
    &:before {
      width: 100%;
    }
  }
}
</style>
