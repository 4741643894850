<script setup lang="ts">
import type { LoginMethods } from '@/compositions/gaUtils'
import benefitIcon from '@/assets/components/benefitsComponent/icon_benefit-min.png'
import benefitIconPlaceHolder from '@/assets/components/benefitsComponent/icon_benefit-small-min.png'
import { benefitDialogVisibility } from '@/components/layout/GoatBenefit/GoatBenefitDialog'
import {
  forgotPasswordMode,
  goatBenefits,
  oldTokens,
  openLoginDialog,
  registerMode,
  RegisterModeActions,
  setLoginGaContext
} from '@/components/layout/LoginDialog/LoginDialog'
import { contextSection, visibilitySelectorGame } from '@/components/layout/SelectorGame/SelectorGameCompose'
import { ArrowRightBold } from '@element-plus/icons-vue'

const { list, buyOffer, getOldGameTokens, GameKeyEnum, gameLoggedOrder } = gamesUtils
const { gaLoginMethods } = gaUtils
const accordionModel = ref<LoginMethods>(gaLoginMethods.goat)
const showHowMode = ref(false)
function onClosed() {
  forgotPasswordMode.value = ''
  registerMode.value = ''
  showHowMode.value = false
  accordionModel.value = gaLoginMethods.goat
  oldTokens.value = []
}

const games = gameLoggedOrder.value.map((idGame) => {
  return list[idGame]
}).filter(game => game.active)

const route = useRoute()
const { gaSections } = gaUtils
const gaSelectorContext = computed(() => {
  return { gs_route: String(route.name), gs_section: contextSection.value || gaSections.btnLogin }
})
function logIn(gameKey: Games.GameKeyType) {
  buyOffer.value = null
  visibilitySelectorGame.value = false
  openLoginDialog(gameKey, { gs_game: gameKey, ...gaSelectorContext.value }, gaLoginMethods.gameToken)
  contextSection.value = ''
}
function onOpenSelectorDialog() {
  setLoginGaContext({ gs_game: GameKeyEnum.goat, ...gaSelectorContext.value })
  oldTokens.value = getOldGameTokens()
}
</script>

<template>
  <el-dialog
    v-model="visibilitySelectorGame"
    modal-class="bg-black/80"
    class="gs-selector-login-dialog rounded-2xl w-11/12 text-center overflow-hidden"
    :class="{
      'big:max-w-2xl': !showHowMode,
      'big:max-w-3xl': showHowMode,
      'bg-gs-bg-gradient-from': showHowMode && accordionModel === gaLoginMethods.goat
    }"
    destroy-on-close
    @closed="onClosed"
    @open="onOpenSelectorDialog"
  >
    <template #header>
      <div class="text-center py-4 big:py-8">
        <div class="text-2xl big:text-3xl font-black text-gs-main-color uppercase">
          <template v-if="registerMode.length">
            <template v-if="registerMode === RegisterModeActions.confirmEmail">
              Email verification
            </template>
            <template v-else-if="registerMode === RegisterModeActions.acceptTerms">
              Your Consents
            </template>
            <template v-else>
              Goat account<span class="text-2xl inline-block big:-translate-y-1">*</span>
            </template>
          </template>
          <template v-else-if="forgotPasswordMode.length">
            Forgot password
          </template>
          <template v-else-if="showHowMode">
            <span v-if="accordionModel === gaLoginMethods.gameToken">Game account</span>
            <span
              v-if="accordionModel === gaLoginMethods.goat"
              class="text-white"
            >GOAT account</span>
            <span v-if="accordionModel === gaLoginMethods.deepLink">Log in via app</span>
          </template>
          <template v-else>
            Log in with
          </template>
        </div>
      </div>
    </template>
    <template #default>
      <div
        class="px-2 big:px-10 pb-10 max-w-lg big:max-w-none mx-auto"
        :class="{
          'bg-gs-bg bg-gradient-to-b from-gs-bg-gradient-from to-gs-bg-gradient-to text-white': showHowMode && accordionModel === gaLoginMethods.goat,
          'bg-white': !(showHowMode && accordionModel === gaLoginMethods.goat)
        }"
      >
        <template v-if="showHowMode && !forgotPasswordMode.length">
          <template v-if="accordionModel === gaLoginMethods.gameToken">
            <div
              class="text-sm big:text-xl mb-3 mt-2 big:mt-5"
            >
              This method requires logging into each game separately. Select a game to learn more.
              However, we recommend logging in with a <el-button
                type="primary"
                link
                class="underline underline-offset-2 big:text-base"
                @click="accordionModel = gaLoginMethods.goat ;showHowMode = true"
              >
                GOAT account
              </el-button>, as it offers additional benefits.
            </div>
            <div class="ml-5 big:ml-1 mr-1 big:mx-4 mt-5 mb-16 flex flex-wrap">
              <div class="clear-both w-full">
                <el-button
                  color="#3ba6bc"
                  class="text-white rounded-lg uppercase text-xl mt-5"
                  size="large"
                  style="--el-button-size: 54px"
                  @click="showHowMode = false"
                >
                  back
                </el-button>
              </div>
            </div>
          </template>
          <div
            v-else-if="accordionModel === gaLoginMethods.goat"
            class="flex flex-col gap-2 pt-3 text-left big:text-base"
          >
            <div
              v-for="(benefit, index) in goatBenefits"
              :key="index"
              class="flex flex-row justify-start gap-x-2 big:gap-x-3 w-full"
            >
              <div>
                <el-image
                  :src="benefitIcon"
                  lazy
                  alt="benefit icon"
                  class="w-8 big:w-9"
                >
                  <template #placeholder>
                    <img
                      :src="benefitIconPlaceHolder"
                      alt="benefit icon"
                      class="w-8 big:w-9"
                    >
                  </template>
                </el-image>
              </div>
              <div class="flex-grow pt-0.5 text-left">
                <h2 class="font-semibold text-base">
                  {{ benefit.header }}
                </h2>
                <h3 class="text-sm pr-3">
                  {{ benefit.body }}
                </h3>
              </div>
            </div>
            <div>
              You have access to a dedicated panel where you can manage your GOAT account:
              <a
                href="https://goat.gamesture.com"
                target="_blank"
                class="underline underline-offset-2 big:text-base hover:opacity-90 active:opacity-95"
              >https://goat.gamesture.com</a>.
            </div>
            <div>You can link your GOAT account to your Google or Facebook account and use them to log in.</div>
            <div class="w-full flex justify-center">
              <el-button
                color="#3ba6bc"
                class="text-white rounded-lg uppercase text-xl mt-5"
                size="large"
                style="--el-button-size: 54px"
                @click="showHowMode = false"
              >
                back
              </el-button>
            </div>
          </div>
        </template>
        <template v-else>
          <el-collapse
            v-model="accordionModel"
            accordion
            class="px-2 bg-transparent"
          >
            <el-collapse-item
              :name="gaLoginMethods.goat"
              :disabled="!!(forgotPasswordMode || registerMode).length"
              class="gs-goat bg-gs-bg-gradient-from rounded-2xl px-2 bg-gradient-to-b from-gs-bg-gradient-from to-gs-bg-gradient-to"
              style="--el-color-item: white;"
              :icon="ArrowRightBold"
            >
              <template #title>
                <div class="text-white text-left leading-none">
                  <div
                    v-if="!(forgotPasswordMode || registerMode).length"
                    class="text-xl font-semibold leading-none"
                  >
                    GOAT account<span class="text-xl -translate-y-2">*</span>
                  </div>
                  <div
                    v-if="!forgotPasswordMode.length && registerMode !== RegisterModeActions.confirmEmail && registerMode !== RegisterModeActions.acceptTerms"
                    class="font-light big:text-[1rem]"
                  >
                    One account for all games. See <span
                      class="underline underline-offset-4 cursor-pointer"
                      @click.stop="benefitDialogVisibility = true"
                    >benefits</span>.
                  </div>
                </div>
              </template>
              <div
                id="auth-goat-wrapper"
                class="relative mx-auto max-w-2xl big:max-w-screen-xl"
              >
                <transition name="gs-fade">
                  <GsGoatLoginBox
                    v-if="accordionModel === gaLoginMethods.goat"
                  />
                </transition>
              </div>
            </el-collapse-item>
            <el-collapse-item
              v-if="!(forgotPasswordMode || registerMode).length"
              :name="gaLoginMethods.gameToken"
              class="rounded-2xl px-2 bg-gs-game-primary mt-4"
              :icon="ArrowRightBold"
            >
              <template #title>
                <div class="text-left text-gray-600 leading-none">
                  <div class="text-xl leading-none font-semibold">
                    Games account
                  </div>
                  <div class="font-light big:text-[1rem]">
                    Connect games directly. No benefits.
                  </div>
                </div>
              </template>
              <div class="text-xl font-bold mb-2 text-gray-600">
                Select game
              </div>
              <div class="py-1 px-2 max-w-md md:max-w-none mx-auto">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                  <template
                    v-for="gameItem in games"
                    :key="gameItem.gameKey"
                  >
                    <template v-if="$store?.getters['game/token'](gameItem.gameKey)?.token?.length">
                      <div
                        class="h-14 rounded-lg cursor-pointer opacity-85 hover:opacity-100 active:opacity-90 hover:shadow-md flex justify-between items-center text-white bg-gs-main-color overflow-hidden outline outline-white outline-2"
                      >
                        <router-link
                          :to="gameItem.route.path"
                          class="w-full"
                          @click="visibilitySelectorGame = false"
                        >
                          <div class="flex justify-between items-center text-base font-semibold w-full uppercase">
                            <div>
                              <img
                                alt="game icon"
                                :src="gameItem?.icon"
                                class="size-14 inline-block mr-1.5"
                              >
                              {{ gameItem?.name }}
                            </div>
                            <icon-ify
                              icon="material-symbols:person-check-outline"
                              class="text-3xl mr-3"
                            />
                          </div>
                        </router-link>
                      </div>
                    </template>
                    <div
                      v-else
                      class="h-14 rounded-lg cursor-pointer opacity-85 hover:opacity-100 active:opacity-90 hover:shadow-md flex justify-between items-center text-white bg-gs-main-color overflow-hidden"
                      @click="logIn(gameItem.gameKey)"
                    >
                      <div class="flex justify-between items-center text-base font-semibold w-full uppercase">
                        <div>
                          <img
                            alt="game icon"
                            :src="gameItem?.icon"
                            class="size-14 inline-block mr-1.5"
                          >
                          {{ gameItem?.name }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
          <div
            v-if="!(forgotPasswordMode || registerMode).length"
            class="mt-5"
          >
            Learn more about: <br>
            <el-button
              link
              @click="accordionModel = gaLoginMethods.goat ;showHowMode = true"
            >
              GOAT account
            </el-button>
            |
            <el-button
              link
              class="ml-0"
              @click="accordionModel = gaLoginMethods.gameToken ;showHowMode = true"
            >
              Game account
            </el-button>
          </div>
        </template>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="postcss">
.gs-selector-login-dialog {
  --el-message-close-size: 1.5rem;
  --el-dialog-padding-primary: 0.1;
  .el-dialog {
    &__header {
      margin-right: 0 !important;
    }
  }
  .el-collapse {
    --el-collapse-header-bg-color: transparent;
    --el-collapse-content-bg-color: transparent;
    --el-collapse-header-height: auto;
    &-item{
      &__header {
        position: relative;
        padding: 1rem 0.4rem;
        border-style: none !important;
        max-width: 28rem !important;
        margin-inline: auto !important;
        &.is-active {
          padding-bottom: 0.5rem;
        }
      }
      &__arrow {
        --color: white;
        font-size: 1.2rem;
        transform-origin: center;
        margin-right: 0;
        margin-top: 0;
      }
      &__wrap {
        border-style: none !important;
      }
      &__content {
        border-style: none !important;
      }
      &.is-disabled {
        .el-collapse-item{
          &__header {
            padding-bottom: 0;
          }
          &__arrow {
            display: none;
          }
        }
      }
      &.gs-goat:not(.is-active) {
        background-image: none !important;
      }
    }
  }
  #auth-goat-wrapper .el-form {
    --el-component-size-large: 44px;
    --el-border-radius-base: 6px;
    --el-border-color: #5a5a5a;
    .el-button {
      --el-button-size: 50px;
    }
    .el-form-item {
      position: relative;
      .el-form-item__label {
        padding-left: 5px;
        position: absolute;
        top: -15px;
        left: 0;
        margin:0;
        font-size: 0.6em;
        color: var(--el-color-item);
      }
      &.is-error {
        .el-input__wrapper, .el-checkbox__inner  {
          background-color: var(--el-color-danger-light-7);
          box-shadow: none;
          border: none;
        }
        .el-form-item__error, .el-form-item__label, .el-checkbox__label > span {
          color: var(--el-color-danger-light-5) !important;
        }
      }
    }
    .el-input__wrapper {
      background-color: #f7f7f7;
    }
  }
}
@media (min-width: theme('screens.big')) {
  .gs-selector-login-dialog {
    #auth-goat-wrapper .el-form {
      --el-component-size-large: 56px;

      .el-button {
        --el-button-size: 54px;
      }

      .el-form-item {
        .el-form-item__label {
          top: -15px;
          font-size: 0.8em;
        }
      }
    }
  }
}
</style>
