<script setup lang="ts">
import { gamesUtils } from '@/compositions/gamesUtils'
import { utilsPlugin } from '@/plugins/utilsPlugin'
import { cartDrawerGaContext, cartDrawerGameKey, cartDrawerVisible } from './CartDrawerCompose'

const { windowWidth } = tools
const { routeGame, loading, showCheckout, list, isLoggedGoat } = gamesUtils
const route = useRoute()

const gameKey = computed(() => {
  return routeGame.value?.gameKey || cartDrawerGameKey.value || ''
})

const gameSettings = computed(() => {
  return list[gameKey.value ?? ''] ?? {}
})

const gameCart = computed(() => {
  const _gameCart: Games.Offer[] = utilsPlugin.getApp()?.$store?.getters?.['game/gameCart']?.(gameKey.value)
  return _gameCart
})

function setQty(value: number | undefined, index: number, offerId: number | string | undefined) {
  // https://vuex.vuejs.org/guide/forms.html
  utilsPlugin
    .getApp()
    ?.$store
    ?.commit('game/updateGameCartOffer', [gameKey.value, { qty: value, id: offerId }, index])
}
const drawerWidth = computed(() => {
  return Math.min(windowWidth.value * 0.9, 500)
})

if (!import.meta.env.SSR && route.query?.cart) {
  utilsPlugin.getApp()?.$store.commit('game/clearGameCart', [route.query?.cart || ''])
  utilsPlugin.getApp()?.$router?.replace(route.path)
}
function onClose() {
  loading.value = ''
}
const isSsr = import.meta.env.SSR
const { gaRemoveFromCart, gaSections, gaViewCart } = gaUtils

function removeFromCart(index: number, context: GaUtils.GaContext) {
  const cartItem = gameCart.value[index]
  gaRemoveFromCart(
    {
      items: [{
        item_id: String(cartItem.id),
        item_name: cartItem?.product || cartItem?.name,
        index,
        quantity: cartItem?.qty ?? 1,
        price: cartItem?.discount?.value || cartItem?.price?.value
      }],
      value: cartItem?.discount?.value || cartItem?.price?.value,
      currency: cartItem?.price?.currency,
      ...context,
      gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account}`
    }
  )
  utilsPlugin.getApp()?.$store.commit('game/removeFromGameCart', [gameKey.value, index])
}
function onOpenCart() {
  gaViewCart({
    ...(gameCart.value.length
      ? {
          currency: utilsPlugin.getApp()?.$store.getters['game/loyalty'](gameKey.value)?.cur,
          value: utilsPlugin.getApp()?.$store.getters['game/gameCartSumVal'](gameKey) || 0,
          items: gameCart.value.map((cartItem, index) => {
            return {
              item_id: String(cartItem.id),
              item_name: cartItem?.product || cartItem?.name,
              index,
              quantity: cartItem?.qty ?? 1,
              price: cartItem?.discount?.value || cartItem?.price?.value
            }
          })
        }
      : { items: [] }),
    gs_game: cartDrawerGaContext.value?.gs_game || gameKey.value,
    gs_route: cartDrawerGaContext.value?.gs_route || String(utilsPlugin.getApp()?.$route?.name),
    gs_section: cartDrawerGaContext.value?.gs_section || gaSections.cart,
    gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account}`
  })
}
</script>

<template>
  <el-drawer
    v-if="!isSsr"
    v-model="cartDrawerVisible"
    :size="`${drawerWidth}px`"
    modal-class="cart-drawer"
    @closed="onClose"
    @opened="onOpenCart"
  >
    <template #header>
      <div class="w-full flex justify-start items-center gap-x-2">
        <div>
          <icon-ify
            icon="tdesign:cart"
            class="inline text-2xl"
          />
        </div>
        <div v-if="gameSettings?.logo">
          <img
            :src="gameSettings.logo"
            alt="logo"
            class="w-28 max-h-12"
          >
        </div>
      </div>
    </template>
    <template #default>
      <div class="flex flex-col gap-y-4">
        <div
          v-for="(offer, index) in gameCart"
          :key="offer.id"
        >
          <div class="flex flex-row gap-1 items-center">
            <div class="w-3/12 pr-2">
              <img
                v-if="offer.asset"
                :src="offer.asset"
                loading="lazy"
                alt="offer img"
              >
            </div>
            <div class="w-9/12 relative pr-8">
              <div class="font-bold truncate">
                {{ offer.name || `Offer_${offer.id}` }}
              </div>
              <div class="flex flex-row flex-wrap gap-2 big:gap-x-3 text-sm text-neutral-600">
                <div>{{ offer?.discount?.format || offer?.price?.format }}</div>
                <div class="whitespace-nowrap">
                  x
                  <span v-if="!offer.allowQty || (offer.purchaseLimit || 1) === 1"> 1 </span>
                  <el-input-number
                    v-else
                    :model-value="offer.qty || 1"
                    :step="1"
                    step-strictly
                    :min="1"
                    :max="offer.purchaseLimit || 1"
                    size="small"
                    class="w-24 ml-1"
                    @update:model-value="setQty($event, index, offer?.id)"
                  />
                </div>
              </div>
              <el-popconfirm
                v-if="cartDrawerVisible"
                title="Are you sure?"
                class="test"
                @confirm="removeFromCart(index, { gs_game: gameKey, gs_route: String($route.name), gs_section: gaSections.cart })"
              >
                <template #reference>
                  <div class="cursor-pointer outline-0 absolute bottom-1 -right-1 hover:text-red-500 pl-3 big:pl-0 z-10">
                    <icon-ify
                      icon="ph:trash"
                      class="cursor-pointer outline-0"
                    />
                  </div>
                </template>
              </el-popconfirm>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="mb-1">
        <el-button
          :disabled="!gameCart?.length || !$store?.getters['game/gameCartPaymentAvailable'](gameKey)"
          class="text-white bg-neutral-300 [&:not(.is-disabled)]:bg-yellow-500 [&:not(.is-disabled)]hover:bg-yellow-400 rounded-lg text-lg px-6 py-2 h-auto tracking-wide uppercase font-semibold w-full min-h-12"
          size="large"
          :loading="loading === 'cart'"
          @click="showCheckout(gaSections.cart, true, gameKey)"
        >
          <div class="flex justify-center items-center flex-wrap gap-x-2 gap-y-0 leading-tight">
            <div>Payment</div>
            <div>{{ $store?.getters['game/gameCartSumValHuman'](gameKey) }}</div>
          </div>
        </el-button>
        <div
          v-if="!$store?.getters['game/gameCartPaymentAvailable'](gameKey)"
          class="text-left text-xs big:-mb-2"
        >
          The minimum cart value is: <b>{{ $store.getters['game/loyalty'](gameKey)?.minPurchaseCurHuman }}</b>.
        </div>
      </div>
    </template>
  </el-drawer>
</template>

<style lang="postcss">
.cart-drawer {
  .el-drawer {
    &__header {
      margin-bottom: 0;
    }

    &__footer {
      box-shadow: 0 -4px 3px rgb(246 239 239 / 75%);
    }
  }
}
</style>
