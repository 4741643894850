<script setup lang="ts">
import {
  connectionDialogBuyMode,
  connectionDialogGameKey,
  visibilityConnectDialog
} from '@/components/shared/GoatConnectGameDialog/GoatConnectGameDialog'
import { ElNotification } from 'element-plus'
import { GameKeyEnum } from '../../../types/enums'

const { getGameSettings, goadLoadAliveData, getGameToken } = gamesUtils
const showHowMode = ref(false)
const notConnectedAlert = ref(false)
const connectionCheckingLoading = ref(false)
function beforeClose(done: () => void) {
  notConnectedAlert.value = false
  if (showHowMode.value) {
    showHowMode.value = false
  } else {
    done()
  }
}
function onClose() {
  connectionDialogGameKey.value = null
  connectionCheckingLoading.value = false
  connectionDialogBuyMode.value = false
}

const gameSettings = computed(() => {
  if (connectionDialogGameKey.value) {
    return getGameSettings(connectionDialogGameKey.value)
  }
  return null
})
const { gaVerifyBtnClick } = gaUtils
async function handleVerify() {
  connectionCheckingLoading.value = true
  await goadLoadAliveData()
  connectionCheckingLoading.value = false
  gaVerifyBtnClick({ gs_game: connectionDialogGameKey.value || '', gs_context: `connected=${!!getGameToken(connectionDialogGameKey.value)?.id}` })
  if (connectionDialogGameKey.value && !getGameToken(connectionDialogGameKey.value)?.id) {
    ElNotification.closeAll()
    notConnectedAlert.value = true
    return false
  }
  visibilityConnectDialog.value = false
}
</script>

<template>
  <el-dialog
    v-model="visibilityConnectDialog"
    align-center
    modal-class="bg-black/80"
    class="goat-connect-dialog rounded-2xl bg-white text-center overflow-hidden"
    width="min(95vw, 750px)"
    :class="{ 'big:max-w-2xl': !showHowMode, 'big:max-w-3xl': showHowMode }"
    destroy-on-close
    :before-close="beforeClose"
    @close="onClose()"
  >
    <template #header>
      <div
        class="uppercase text-2xl flex justify-center items-center font-extrabold px-5 pt-5 pb-2"
      >
        <div
          v-if="!connectionDialogBuyMode"
          class="text-gs-bg -mb-2"
        >
          connect the game
        </div>
      </div>
    </template>
    <template #default>
      <div class="bg-white pt-3 big:pt-5 px-3 big:px-10 max-w-lg big:max-w-none mx-auto">
        <div
          class="h-14 rounded-lg  flex justify-between items-center text-white bg-gs-main-color overflow-hidden"
        >
          <div class="text-xl font-semibold">
            <img
              alt="game icon"
              :src="gameSettings?.icon"
              class="size-14 inline-block mr-1.5"
            >
            {{ gameSettings?.name }}
          </div>
        </div>
        <div class="font-extrabold text-2xl uppercase">
          <div
            v-if="connectionDialogBuyMode"
            class="mt-5 text-lg big:text-xl"
          >
            First, Connect the Game
          </div>
        </div>
        <template v-if="showHowMode">
          <div class="mx-1 big:mx-4 mt-5 mb-16 flex flex-wrap">
            <ul class="text-left list-decimal text-sm big:text-base font-medium big:w-1/2 mb-5 pl-5">
              <li>Open the {{ gameSettings?.name }} game on your mobile device and go to Settings.</li>
              <li>Find the {{ gameSettings?.gameKey === GameKeyEnum.questland ? 'Manage Your Account' : 'Account Connection' }} section.</li>
              <li>Tap the Login with GOAT Account button.</li>
              <li>You can log in using the form or social login. If you don’t have a GOAT account, tap the Register button.</li>
              <li>Return to the store and tap the Verify button or refresh the data.</li>
            </ul>
            <div
              v-if="gameSettings?.connectInImg?.length"
              class="big:w-1/2 flex justify-center items-center flex-col w-full"
            >
              <el-image
                width="710"
                height="768"
                class="px-2 mt-2 max-w-40 big:max-w-none"
                :src="gameSettings?.connectInImg[0]"
                :zoom-rate="0"
                :max-scale="1"
                :min-scale="1"
                :preview-src-list="gameSettings?.connectInImg"
                fit="cover"
              />
              <div class="text-sm text-neutral-150 w-full italic tracking-wide">
                View Preview <br>(4 Screenshots)
              </div>
            </div>
            <div class="clear-both w-full">
              <el-button
                color="#2c7c8b"
                class="text-white tracking-wide rounded-lg uppercase text-lg mini:text-xl mt-5 px-10 font-semibold"
                size="large"
                style="--el-button-size: 2.5em"
                @click="showHowMode = false"
              >
                back
              </el-button>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            id="connect-dialog-body"
            class="mt-3 mb-8 big:mb-12 relative"
          >
            <transition name="el-fade-in-linear">
              <div
                v-if="notConnectedAlert"
                class="text-[#ff0200] text-sm big:text-lg py-3 border-[3px] border-[#ff0200] rounded-2xl font-bold mb-3 flex items-center justify-center"
              >
                <icon-ify
                  icon="cuida:alert-outline"
                  class="text-2xl mr-1"
                />
                <div>The game is not connected to the account</div>
              </div>
            </transition>
            <div
              class="text-sm big:text-lg text-neutral-600 font-semibold px-1"
            >
              <template v-if="connectionDialogBuyMode">
                To continue shopping, please connect your GOAT account to the game. Open the game and log in using your GOAT account.
                For detailed instructions, [<a
                  href="#"
                  class="text-gs-main-color text-lg big:text-xl font-bold hover:text-cyan-600 inline whitespace-nowrap"
                  @click="showHowMode = true"
                >click here</a>]. Once done, return to this page and click the verify button below.
              </template>
              <div
                v-else
                class="pt-2"
              >
                To connect the game to your account, open the game and log in using your GOAT account.
                For detailed instructions, [<a
                  href="#"
                  class="text-gs-main-color text-lg big:text-xl font-bold hover:text-cyan-600 inline whitespace-nowrap"
                  @click="showHowMode = true"
                >click here</a>]. Once completed, return to this page and click the verify button below.
              </div>
            </div>
            <el-button
              color="#2c7c8b"
              class="text-white tracking-wide rounded-lg uppercase text-lg mini:text-xl mt-5 px-10 font-semibold"
              size="large"
              style="--el-button-size: 2.5em"
              :loading="!!connectionDialogGameKey && connectionCheckingLoading"
              @click="handleVerify"
            >
              verify
            </el-button>
          </div>
        </template>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="postcss">
.goat-connect-dialog {
  --el-message-close-size: 1.5rem;
  --el-dialog-padding-primary: 0.1;
  .el-dialog {
    &__header {
      margin-right: 0 !important;
    }
  }
  .el-input__inner {
    --el-input-inner-height: 48px;
  }
  .el-image-viewer__actions {
    display: none !important;
  }
}
</style>
