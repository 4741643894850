<script setup lang="ts">
import { logInGameKey, showHowMode } from '@/components/layout/LoginDialog/LoginDialog'

interface Props {
  deepLinkUrl?: string
}
defineProps<Props>()
const { buyOffer } = gamesUtils
function onClickDeepLink(event: MouseEvent) {
  const target = event.currentTarget as HTMLAnchorElement
  const url = target.href
  if (url) {
    localStorage?.setItem?.('buyOffer', JSON.stringify(buyOffer.value))
    window.location.href = url
  }
}
const gameSettings = computed(() => {
  return gamesUtils.getGameSettings(buyOffer.value?.gameKey || gamesUtils.routeGame.value?.gameKey || logInGameKey.value)
})
</script>

<template>
  <div>
    <div class="leading-none text-[0.9rem] text-neutral-600 font-normal mt-1 mb-5 text-justify hyphens-auto px-2">
      This method requires the game to be installed on your device. Not all browsers support this method. It is recommended to run the game first.
    </div>
    <div class="px-2 mb-0">
      <div
        class="h-12 rounded-lg flex justify-between items-center text-white bg-gs-main-color overflow-hidden"
      >
        <div class="flex justify-between items-center text-base font-semibold w-full uppercase">
          <div>
            <img
              alt="game icon"
              :src="gameSettings?.icon"
              class="size-12 inline-block mr-1.5"
            >
            {{ gameSettings?.name }}
          </div>
        </div>
      </div>
      <div class="text-right">
        <el-button
          link
          size="large"
          class="underline underline-offset-2 text-xs"
          @click.stop="showHowMode = true"
        >
          see more
        </el-button>
      </div>
    </div>
    <a
      :href="deepLinkUrl"
      @click.prevent="onClickDeepLink"
    >
      <el-button
        color="#2c7c8b"
        class="text-white rounded-lg uppercase text-xl mt-3 mb-2 big:mb-5 w-full max-w-56 font-semibold px-3"
        size="large"
        style="--el-button-size: 50px;"
      >
        <icon-ify
          icon="pepicons-pop:enter"
          class="text-2xl mr-2"
        />
        Log in via app
      </el-button>
    </a>
  </div>
</template>

<style scoped lang="postcss">

</style>
