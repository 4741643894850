<script setup lang="ts">
import { visibilityPaymentMethod } from '@/components/layout/PaymentMethod/PaymentMethodCompose'
import { payPro, url } from '@/components/layout/PayPro/PayProCompose'

const { visibility, loading } = payPro
const { buyOffer, triggerApi } = gamesUtils

const redirectToPayPro = true
loading.value = ''
function onLoadIframe() {
  document.getElementsByClassName('ppg-loader')?.[0]?.remove?.()
  document.getElementById('ppg-btn-close')?.classList?.add?.('ppg-show')
}

watch(visibility, () => {
  if (visibility.value) {
    if (redirectToPayPro) {
      window.location.href = url.value
    } else {
      visibilityPaymentMethod.value = false
    }
  } else {
    triggerApi.callTrigger()
    loading.value = ''
    buyOffer.value = null
  }
})
</script>

<template>
  <div
    v-if="visibility && url && !redirectToPayPro"
    id="ppg-checkout-modal"
    class="ppg-checkout-modal ppg-show"
  >
    <div
      id="ppg-btn-close"
      class="ppg-btn-close"
      @click.prevent="visibility = false"
    >
      <img
        src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIj48cGF0aCBkPSJNNDQzLjYgMzg3LjEgMzEyLjQgMjU1LjRsMTMxLjUtMTMwYzUuNC01LjQgNS40LTE0LjIgMC0xOS42bC0zNy40LTM3LjZjLTIuNi0yLjYtNi4xLTQtOS44LTQtMy43IDAtNy4yIDEuNS05LjggNEwyNTYgMTk3LjggMTI0LjkgNjguM2MtMi42LTIuNi02LjEtNC05LjgtNC0zLjcgMC03LjIgMS41LTkuOCA0TDY4IDEwNS45Yy01LjQgNS40LTUuNCAxNC4yIDAgMTkuNmwxMzEuNSAxMzBMNjguNCAzODcuMWMtMi42IDIuNi00LjEgNi4xLTQuMSA5LjggMCAzLjcgMS40IDcuMiA0LjEgOS44bDM3LjQgMzcuNmMyLjcgMi43IDYuMiA0LjEgOS44IDQuMSAzLjUgMCA3LjEtMS4zIDkuOC00LjFMMjU2IDMxMy4xbDEzMC43IDEzMS4xYzIuNyAyLjcgNi4yIDQuMSA5LjggNC4xIDMuNSAwIDcuMS0xLjMgOS44LTQuMWwzNy40LTM3LjZjMi42LTIuNiA0LjEtNi4xIDQuMS05LjgtLjEtMy42LTEuNi03LjEtNC4yLTkuN3oiIGZpbGw9IiNmZmZmZmYiIGNsYXNzPSJmaWxsLTAwMDAwMCI+PC9wYXRoPjwvc3ZnPg=="
        alt="logo"
      >
    </div>
    <div class="ppg-loader" />
    <iframe
      id="ppg-iframe"
      class="ppg-iframe"
      :src="url"
      frameborder="0"
      :onload="onLoadIframe"
    />
  </div>
</template>

<style lang="postcss">
.ppg-checkout-modal {
  z-index: 99999;
  display: none;
  background-color: rgba(0, 0, 0, 0.6);
  border: 0 none transparent;
  visibility: visible;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.ppg-checkout-modal.ppg-show {
  display: block;
}
.ppg-btn-close {
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  top: 20px;
  right: 35px;
  background: rgb(0 0 0 / 35%);
  height: 50px;
  width: 50px;
  border: none;
  outline: none;
  cursor: pointer;
}
.ppg-btn-close.ppg-show {
  display: flex;
}
.ppg-btn-close img {
  width: 24px;
}
.ppg-iframe {
  width: 100%;
  height: 100%;
  border: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.ppg-loader {
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  width: 48px;
  height: 48px;
  border: 5px solid #000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ppg-rotation 1s linear infinite;
}

@keyframes ppg-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
