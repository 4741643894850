import { getLoginGaContext, openLoginDialog, setLoginGaContext } from '@/components/layout/LoginDialog/LoginDialog'

const { forceMobile, isMobileDevice } = tools
const { buyOffer, getGameToken, checkBuyOfferWithApi } = gamesUtils
const { gaLoginMethods } = gaUtils

function initForceMobile() {
  forceMobile.value = !!localStorage?.getItem?.('forceMobile')
}
function initBuyOfferStoredForDeepLinkFlow() {
  const buyOfferDeepLink: Games.Offer | null = JSON.parse(String(localStorage?.getItem?.('buyOffer')))
  const fakeLoadingRef = ref('')
  if (buyOfferDeepLink?.deepLink && isMobileDevice.value) {
    buyOffer.value = {
      ...buyOfferDeepLink
    }
    if (!getGameToken(buyOfferDeepLink.gameKey)?.token) {
      openLoginDialog(buyOffer.value.gameKey, { ...getLoginGaContext(), ...{ method: gaLoginMethods.deepLink } })
    } else {
      checkBuyOfferWithApi(fakeLoadingRef)
      setLoginGaContext(null)
    }
  } else {
    buyOffer.value = null
    setLoginGaContext(null)
  }
  localStorage?.setItem?.('buyOffer', 'null')
}

function onMountedApp() {
  initForceMobile()
  initBuyOfferStoredForDeepLinkFlow()
}

export { onMountedApp }
