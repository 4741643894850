<script setup lang="ts">
import { benefitDialogVisibility } from '@/components/layout/GoatBenefit/GoatBenefitDialog'
import { openGoatUserBoxDialog } from '@/components/layout/GoatUserBoxDialog/GoatUserBoxDialog'
import { visibilitySelectorGame } from '@/components/layout/SelectorGame/SelectorGameCompose'
import LoyaltyBoxesInfoComponent from '@/components/shared/LoyaltyBoxesInfoComponent.vue'
import { GameKeyEnum } from '@/types/enums'
import LogoHands from '/src/assets/logo_hands.svg'

const { buyOffer, logOut, showLogInDialog, isLogged, routeGame, gameLoyalty, isLoggedGoat, getGoatData, getGameTokens } = gamesUtils
const { isDesktop } = tools
const { gaSections } = gaUtils

function logOutUser() {
  logOut(routeGame.value?.gameKey)
  buyOffer.value = null
}

const withSelectGame = computed(() => {
  return !routeGame.value?.id
})

const isConnected = computed(() => {
  if (!routeGame.value?.id) {
    return Object.entries(getGameTokens()).some(([keyOfGame, tokenData]) => {
      if (keyOfGame === GameKeyEnum.goat) {
        return false
      }
      return tokenData?.token?.length > 0
    })
  } else {
    return isLogged.value
  }
})
</script>

<template>
  <div class="login-btn-component">
    <template v-if="withSelectGame && !isLoggedGoat">
      <el-button
        color="#3ba6bc"
        class="text-white rounded-lg uppercase relative ease-in-out duration-700"
        size="large"
        v-bind="$attrs"
        @click="visibilitySelectorGame = true"
      >
        <div class="text-base tracking-wide pr-8">
          log in
          <div class="absolute top-0 right-2 bottom-0 w-10 p-1 flex items-center">
            <LogoHands class="max-h-full w-full h-full" />
          </div>
        </div>
      </el-button>
    </template>
    <transition
      v-else
      name="el-fade-in-linear"
      mode="out-in"
    >
      <template v-if="!isLogged && !isLoggedGoat">
        <el-button
          color="#3ba6bc"
          class="text-white rounded-lg uppercase relative ease-in-out duration-700"
          size="large"
          v-bind="$attrs"
          @click="showLogInDialog(true, { gs_game: routeGame?.gameKey, gs_route: String($route.name), gs_section: gaSections.btnLogin })"
        >
          <div class="text-base tracking-wide pr-8">
            log in
            <div
              v-if="routeGame?.icon"
              class="absolute top-0 right-1 bottom-0 w-10 p-1 flex items-center"
            >
              <el-image
                :src="routeGame.icon"
                class="max-h-full rounded-2xl shadow-md shadow-gs-bg"
              />
            </div>
          </div>
        </el-button>
      </template>
      <template v-else-if="isLoggedGoat">
        <div v-if="isDesktop">
          <el-popover
            placement="bottom"
            :show-after="300"
            :offset="10"
            width="500px"
            popper-class="rounded-2xl p-0 bg-white"
            :persistent="false"
          >
            <template #reference>
              <div
                class="mx-auto max-w-xs"
              >
                <div
                  class="rounded-xl px-0 py-1 bg-gs-game-primary text-gs-main-color flex flex-col items-center min-w-40"
                >
                  <goat-avatar-section
                    :game-key="routeGame?.gameKey || GameKeyEnum.goat"
                    size="size-[32px]"
                    class="text-base"
                  />
                  <div
                    class="flex items-center justify-end w-full pl-2"
                  >
                    <goat-connect-btn
                      v-if="!isConnected"
                      :game-settings="routeGame"
                      :game-key="routeGame?.gameKey || GameKeyEnum.goat"
                      class="mt-1 mx-auto"
                      height="h-6"
                      size="default"
                      full-text
                      text-class="text-small"
                      icon
                      icon-class="w-6 size-6 -mr-1.5"
                      :section="gaSections.header"
                      @click.stop
                    />
                    <goat-loyalty-field
                      v-else
                      class="text-sm mr-2"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template #default>
              <goat-user-box
                :game-key="routeGame?.gameKey || GameKeyEnum.goat"
                popup-mode
              />
            </template>
          </el-popover>
        </div>
        <template v-else>
          <div
            class="mx-auto  max-w-[min(500px,95vw)]"
          >
            <div
              class="rounded-2xl pl-1.5 pr-3 big:px-3 py-4 bg-gs-game-primary text-gs-main-color flex flex-col gap-2 items-center"
              @click="openGoatUserBoxDialog(routeGame?.gameKey || GameKeyEnum.goat, gaSections.mobileMenu)"
            >
              <div class="grid grid-cols-[1fr_auto] items-center justify-start gap-x-2 w-full px-2 text-left">
                <div class="grid grid-cols-1 gap-1.5 justify-start font-semibold items-center">
                  <div class="grid grid-cols-[auto_1fr] items-center justify-start">
                    <goat-avatar
                      size="w-[35px]"
                      :game-key="GameKeyEnum.goat"
                      class="cursor-pointer"
                    />
                    <div class="truncate">
                      {{ getGoatData?.email }}
                    </div>
                  </div>
                </div>
                <div>
                  <el-button
                    link
                    size="default"
                  >
                    <div class="text-xs tracking-wide underline underline-offset-2 whitespace-nowrap text-neutral-600">
                      See more
                    </div>
                  </el-button>
                </div>
              </div>
              <div
                v-if="!isConnected"
                class="w-full max-w-72"
              >
                <goat-connect-btn
                  v-if="routeGame?.gameKey"
                  class="w-full"
                  :game-settings="routeGame"
                  :game-key="routeGame.gameKey"
                  text-class="text-base md:text-base"
                  full-text
                  icon
                  game-variant
                  height="h-12"
                  icon-class="size-12"
                  :section="gaSections.mobileMenu"
                  @click.stop
                />
                <goat-connect-btn
                  v-else
                  :game-key="GameKeyEnum.goat"
                  class="w-full max-w-52"
                  text-class="text-base md:text-base"
                  full-text
                  icon
                  :section="gaSections.mobileMenu"
                />
              </div>
            </div>
          </div>
        </template>
      </template>
      <div
        v-else
        class="mx-auto ease-in-out duration-700 w-full max-w-[340px]"
      >
        <div class="rounded-2xl p-3 big:p-2 bg-gs-game-primary text-gs-main-color">
          <div class="flex justify-between items-center">
            <div class="flex items-center justify-start">
              <el-popover
                v-if="isDesktop"
                placement="bottom"
                :show-after="300"
                :offset="25"
                width="auto"
                popper-class="rounded-2xl"
                popper-style="--el-bg-color-overlay: #dddddd"
              >
                <template #reference>
                  <div class="min-w-36">
                    <div class="flex items-center  flex-grow big:max-w-max">
                      <icon-ify
                        icon="mdi:user-outline"
                        class="text-2xl text-gs-main-color"
                      />
                      <div
                        class="grow pl-2 text-base big:font-semibold leading-none text-left truncate big:max-w-[155px] xl:max-w-[220px] text-gs-main-color font-semibold"
                      >
                        {{ $store?.getters?.['game/token'](routeGame?.gameKey)?.name }}
                      </div>
                    </div>
                    <div v-if="gameLoyalty?.id">
                      <LoyaltyBoxesInfoComponent
                        :offset="18"
                        popper-class="bg-gs-body-bg rounded-lg"
                        wrapper-class="flex justify-end pl-4 mt-0.5 items-center text-xs text-black"
                        box-class="rounded bg-gs-body-bg pl-4 py-1 h-6 max-w-[200px] flex justify-start items-center shrink-0"
                        box-img-class="w-6"
                        box-icon-class="right-1 top-1/2 -translate-y-1/2 text-sm"
                        :game-key="routeGame?.gameKey || ''"
                      />
                    </div>
                  </div>
                </template>
                <template #default>
                  <div class="px-3">
                    <div class="grid grid-cols-[1fr_auto] items-center gap-2">
                      <goat-player-avatar
                        class="text-base"
                        :game-key="routeGame?.gameKey"
                        logo-game
                      />
                      <div>
                        <div class="flex items-center justify-end mb-1 pr-0.5">
                          <el-button
                            link
                            size="default"
                            @click="logOutUser()"
                          >
                            <div class="text-xs tracking-wide underline underline-offset-2 whitespace-nowrap text-neutral-600">
                              Log out
                              <icon-ify
                                icon="ion:log-out-outline"
                                class="text-lg inline-block"
                              />
                            </div>
                          </el-button>
                        </div>
                      </div>
                    </div>
                    <div class="flex items-center justify-end gap-2 pr-2">
                      <div class="mb-2">
                        <template v-if="gameLoyalty?.id">
                          <LoyaltyBoxesInfoComponent
                            :offset="30"
                            class="w-full"
                            popper-class="bg-gs-body-bg rounded-xl w-[98%] max-w-[400px] shadow-2xl border border-neutral-400"
                            wrapper-class="flex justify-center pl-2 items-center text-sm big:text-base mt-3.5 gap-x-8"
                            box-class="rounded bg-gs-body-bg pl-4 py-1 h-6 flex w-1/3 justify-start items-center text-sm"
                            box-img-class="w-6 mr-1.5"
                            box-icon-class="right-2 top-1/2 -translate-y-1/2 text-base"
                            :game-key="routeGame?.gameKey || ''"
                          >
                            <template #boxes>
                              <energy-and-gems-component
                                class="text-sm"
                                :class="[!!routeGame?.energy?.url && !!routeGame?.gems?.url ? 'w-2/3' : 'w-1/3']"
                                :game-key="routeGame?.gameKey || ''"
                              />
                            </template>
                          </LoyaltyBoxesInfoComponent>
                          <refresh-items-btn class="mt-1" />
                        </template>
                      </div>
                    </div>
                    <div class="flex flex-col gap-1 justify-end">
                      <goat-upgrade-btn :section="gaSections.userBox" />
                      <div class="flex justify-end items-center mt-1">
                        <icon-ify
                          icon="iconamoon:question-mark-circle-light"
                          class="text-xl inline-block text-neutral-600 cursor-pointer mt-0.5"
                          @click="benefitDialogVisibility = true"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </el-popover>
              <template v-else>
                <icon-ify
                  icon="mdi:user-outline"
                  class="text-2xl text-gs-main-color"
                />
                <div class="flex-grow big:max-w-max">
                  <div
                    class="grow pl-2 text-base big:font-semibold leading-none text-left truncate max-w-[180px] big:max-w-[155px] xl:max-w-[220px] text-gs-main-color font-semibold"
                  >
                    {{ $store?.getters?.['game/token'](routeGame?.gameKey)?.name }}
                  </div>
                </div>
              </template>
            </div>
            <div
              v-if="!isDesktop"
              class="pl-2 text-center flex justify-center"
            >
              <el-button
                link
                size="default"
                v-bind="$attrs"
                @click="logOutUser()"
              >
                <div class="text-xs tracking-wide underline underline-offset-2 whitespace-nowrap text-neutral-600">
                  Log out
                  <icon-ify
                    icon="ion:log-out-outline"
                    class="text-lg inline-block ml-1"
                  />
                </div>
              </el-button>
            </div>
          </div>
          <template v-if="!isDesktop">
            <goat-upgrade-btn
              :section="gaSections.mobileMenu"
              class="w-full mt-2"
            />
            <div class="flex justify-end items-center mt-1">
              <icon-ify
                icon="iconamoon:question-mark-circle-light"
                class="text-xl inline-block text-neutral-600 cursor-pointer mt-0.5"
                @click="benefitDialogVisibility = true"
              />
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="postcss">
.el-button:hover {
  .el-image {
    opacity: 50%;
  }
}
</style>
