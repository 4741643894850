<script setup lang="ts">
import benefitIcon from '@/assets/components/benefitsComponent/icon_benefit-min.png'
import benefitIconPlaceHolder from '@/assets/components/benefitsComponent/icon_benefit-small-min.png'
import { benefitDialogVisibility } from '@/components/layout/GoatBenefit/GoatBenefitDialog'
import {
  accordionModel,
  callBacks,
  canBeUpgraded,
  closeLoginDialog,
  confirmedEmail,
  forgotPasswordMode,
  getLoginGaContext,
  goatBenefits,
  logInGameKey,
  oldTokens,
  registerMode,
  RegisterModeActions,
  showHowMode,
  visibilityLogInDialog
} from '@/components/layout/LoginDialog/LoginDialog'
import { ArrowRightBold } from '@element-plus/icons-vue'

const { gaLoginMethods, gaSections } = gaUtils

const { buyOffer, triggerApi, isLoggedGoat, getOldGameTokens } = gamesUtils
const { isMobileDevice, isDesktop } = tools
const gameSettings = computed(() => {
  return gamesUtils.getGameSettings(buyOffer.value?.gameKey || gamesUtils.routeGame.value?.gameKey || logInGameKey.value)
})

const route = useRoute()
const form = ref<{ accountID: string | number, accountToken: string }>({
  accountID: '',
  accountToken: ''
})
const buyMode = ref(true)
const isOpenFromDeepLink = ref(false)
const isConnectMode = ref(false)

function beforeClose(done: () => void) {
  if (showHowMode.value) {
    showHowMode.value = false
  } else {
    done()
  }
}

function onCloseLoginDialog() {
  form.value.accountID = ''
  form.value.accountToken = ''
  buyMode.value = false
  isOpenFromDeepLink.value = false
  accordionModel.value = gaLoginMethods.goat
  forgotPasswordMode.value = ''
  registerMode.value = ''
  confirmedEmail.value = ''
  showHowMode.value = false
  oldTokens.value = []
  closeLoginDialog()
}
const deepLinkUrl = computed(() => {
  if (gameSettings.value?.idApp) {
    return gamesUtils.buildDeepLink(
      gameSettings.value.idApp,
      String(route?.name),
      gameSettings.value.gameKey,
      String(buyOffer.value?.id)
    )
  }
  return ''
})

watch(
  () => visibilityLogInDialog.value,
  () => {
    buyMode.value = !!buyOffer.value?.id
    if (buyOffer.value?.deepLink) {
      buyOffer.value.deepLink = false
      isOpenFromDeepLink.value = true
    }
  }
)
function showForm() {
  buyMode.value = false
}

const logBanMinutes = ref(0)

function onOpenLoginDialog() {
  checkBan()
  oldTokens.value = getOldGameTokens()
}
function checkBan() {
  const banUntil = utilsPlugin.getApp()?.$store?.getters?.['game/logBan']?.(gameSettings.value?.gameKey)
  if (!banUntil) {
    logBanMinutes.value = 0
  }
  const diff = new Date(banUntil * 1000).getTime() - new Date().getTime()
  if (diff > 0) {
    logBanMinutes.value = Math.ceil(diff / 1000 / 60)
  } else {
    logBanMinutes.value = 0
  }
}

watch(
  () => utilsPlugin.getApp()?.$store?.getters?.['game/logBan']?.(gameSettings.value?.gameKey),
  () => {
    checkBan()
  }
)
watch(triggerApi.triggerRef, () => {
  checkBan()
  isConnectMode.value = !!getLoginGaContext()?.connectMode
})
</script>

<template>
  <el-dialog
    v-model="visibilityLogInDialog"
    align-center
    modal-class="bg-black/80"
    class="gs-login-dialog rounded-2xl w-11/12 text-center overflow-hidden"
    :class="{
      'big:max-w-2xl': !showHowMode,
      'big:max-w-3xl': showHowMode,
      'bg-gs-bg-gradient-from': showHowMode && accordionModel === gaLoginMethods.goat
    }"
    destroy-on-close
    :before-close="beforeClose"
    @close="onCloseLoginDialog()"
    @paste="callBacks?.paste()"
    @open="onOpenLoginDialog"
  >
    <template #header>
      <div class="text-center pt-4">
        <div class="text-2xl big:text-3xl font-black text-gs-main-color uppercase">
          <template v-if="!showHowMode">
            <template v-if="isLoggedGoat">
              Connect the game
            </template>
            <template v-else-if="registerMode.length">
              <template v-if="registerMode === RegisterModeActions.confirmEmail">
                Email verification
              </template>
              <template v-else-if="registerMode === RegisterModeActions.acceptTerms">
                Your Consents
              </template>
              <template v-else>
                Goat account<span class="text-2xl inline-block big:-translate-y-1">*</span>
              </template>
            </template>
            <template v-else-if="forgotPasswordMode.length">
              Forgot password
            </template>
            <template v-else>
              Log in with
            </template>
          </template>
        </div>
      </div>
    </template>
    <template #default>
      <div
        class="pt-3 big:pt-5 px-2 big:px-10 pb-3 max-w-lg big:max-w-none mx-auto"
        :class="{
          'bg-gs-bg bg-gradient-to-b from-gs-bg-gradient-from to-gs-bg-gradient-to text-white': showHowMode && accordionModel === gaLoginMethods.goat,
          'bg-white': !(showHowMode && accordionModel === gaLoginMethods.goat)
        }"
      >
        <div
          v-if="!(forgotPasswordMode || registerMode).length"
          class="font-extrabold text-2xl uppercase"
        >
          <template v-if="showHowMode">
            <span v-if="accordionModel === gaLoginMethods.gameToken">Game account</span>
            <span v-if="accordionModel === gaLoginMethods.goat">GOAT account</span>
            <span v-if="accordionModel === gaLoginMethods.deepLink">Log in via app</span>
          </template>
        </div>
        <template v-if="buyMode && ((isMobileDevice && !isOpenFromDeepLink) || !isMobileDevice)">
          <template v-if="!isLoggedGoat">
            <div class="mt-5 text-xl max-w-[380px] mx-auto">
              To continue shopping, you must log in. You'll also see more offers,
              including offers just for you.
            </div>
            <div class="my-5">
              <el-button
                color="#3ba6bc"
                class="text-white rounded-lg uppercase text-xl mb-5"
                size="large"
                style="--el-button-size: 54px"
                @click="showForm"
              >
                Log in
              </el-button>
            </div>
          </template>
          <template v-else>
            <div class="mt-5 text-xl max-w-[380px] big:max-w-full mx-auto">
              To continue shopping, you need to connect your GOAT account with the game.
              You'll also enjoy <a
                href="#"
                class="underline underline-offset-4 hover:text-gs-main-color active:text-gs-main-color/90 whitespace-nowrap"
                @click.prevent.stop="benefitDialogVisibility = true"
              >all the benefits</a> and gain access to more offers, including personalized ones just for you.
            </div>
            <div class="px-2 text-center flex justify-center items-center mt-8 mb-2">
              <goat-connect-btn
                :game-settings="gameSettings"
                :game-key="gameSettings?.gameKey"
                :full-text="isDesktop"
                size="large"
                style="--el-button-size: 54px"
                icon
                :section="`${buyOffer?.gaContext?.gs_section},${gaSections.buyBtn}`"
              />
            </div>
          </template>
        </template>
        <template v-else-if="showHowMode">
          <template v-if="accordionModel === gaLoginMethods.gameToken">
            <div
              class="text-sm big:text-xl mb-3 mt-2 big:mt-5"
            >
              To log in to the game with game account, you need to enter
              the Player ID and Game Token, which you can find in the game settings.
              However, we recommend logging in with a <el-button
                type="primary"
                link
                class="underline underline-offset-2 big:text-base"
                @click="accordionModel = gaLoginMethods.goat ;showHowMode = true"
              >
                GOAT account
              </el-button>, as it offers additional benefits.
            </div>
            <div class="ml-5 big:ml-1 mr-1 big:mx-4 mt-5 mb-16 flex flex-wrap">
              <ul class="text-left list-decimal text-sm big:text-base font-medium big:w-1/2 mb-5">
                <li>Open a {{ gameSettings?.name }} game on you mobile device</li>
                <li>Find in-game Settings</li>
                <li>Find section with PlayerID and Token</li>
                <li>You can copy the above data to the clipboard using the Copy button</li>
                <li>Return to the store and enter the above data into the form (paste or enter manually)</li>
                <li>Click confirm</li>
              </ul>
              <div
                v-if="gameSettings?.logInImg?.length"
                class="big:w-1/2"
              >
                <el-image
                  width="710"
                  height="768"
                  class="px-2 mt-2"
                  :src="gameSettings?.logInImg[0]"
                  :zoom-rate="0"
                  :max-scale="1"
                  :min-scale="1"
                  :preview-src-list="gameSettings?.logInImg"
                  fit="cover"
                />
                <div class="text-sm text-neutral-150 w-full italic tracking-wide">
                  preview
                </div>
              </div>
              <div class="clear-both w-full">
                <el-button
                  color="#3ba6bc"
                  class="text-white rounded-lg uppercase text-xl mt-5"
                  size="large"
                  style="--el-button-size: 54px"
                  @click="showHowMode = false"
                >
                  back to form
                </el-button>
              </div>
            </div>
          </template>
          <div
            v-else-if="accordionModel === gaLoginMethods.deepLink"
            class="text-justify hyphens-auto pt-3 flex flex-col gap-2 big:text-base px-2"
          >
            <div>
              This method involves opening the game app and logging in through it. One click, and that's it.
            </div>
            <div>
              If the game cannot be opened, you will be redirected to the login form for your
              <span
                class="text-gs-main-color hover:opacity-90 font-normal underline underline-offset-2 cursor-pointer"
                @click="accordionModel = gaLoginMethods.gameToken ;showHowMode = true"
              >Game account</span>
            </div>
            <div>This method requires the game to be installed on your device. Not all browsers support this method. It is recommended to run the game first.</div>
            <div>
              However, we recommend logging in with a
              <span
                class="text-gs-main-color hover:opacity-90 font-normal underline underline-offset-2 cursor-pointer"
                @click="accordionModel = gaLoginMethods.goat ;showHowMode = true"
              >GOAT account</span>, as it offers additional benefits.
            </div>
            <div class="w-full flex justify-center">
              <el-button
                color="#3ba6bc"
                class="text-white rounded-lg uppercase text-xl mt-5 font-semibold"
                size="large"
                style="--el-button-size: 50px"
                @click="showHowMode = false"
              >
                back to form
              </el-button>
            </div>
          </div>
          <div
            v-else-if="accordionModel === gaLoginMethods.goat"
            class="flex flex-col gap-2 pt-3 text-left big:text-base text-white"
          >
            <div
              v-for="(benefit, index) in goatBenefits"
              :key="index"
              class="flex flex-row justify-start gap-x-2 big:gap-x-3 w-full"
            >
              <div>
                <el-image
                  :src="benefitIcon"
                  lazy
                  alt="benefit icon"
                  class="w-8 big:w-9"
                >
                  <template #placeholder>
                    <img
                      :src="benefitIconPlaceHolder"
                      alt="benefit icon"
                      class="w-8 big:w-9"
                    >
                  </template>
                </el-image>
              </div>
              <div class="flex-grow pt-0.5 text-left">
                <h2 class="font-semibold text-base">
                  {{ benefit.header }}
                </h2>
                <h3 class="text-sm pr-3">
                  {{ benefit.body }}
                </h3>
              </div>
            </div>
            <div>
              You have access to a dedicated panel where you can manage your GOAT account:
              <a
                href="https://goat.gamesture.com"
                target="_blank"
                class="underline underline-offset-2 big:text-base hover:opacity-90 active:opacity-95"
              >https://goat.gamesture.com</a>.
            </div>
            <div>You can link your GOAT account to your Google or Facebook account and use them to log in.</div>
            <div class="w-full flex justify-center">
              <el-button
                color="#3ba6bc"
                class="text-white rounded-lg uppercase text-xl mt-5 mb-5"
                size="large"
                style="--el-button-size: 54px"
                @click="showHowMode = false"
              >
                back to form
              </el-button>
            </div>
          </div>
        </template>
        <div
          v-else-if="logBanMinutes"
          class="mt-5 mb-16 text-xl"
        >
          Too many failed attempts. Please wait {{ logBanMinutes }} minute{{ logBanMinutes > 1 ? 's' : '' }} before trying again.
        </div>
        <template v-else>
          <div class="auth-wrapper mb-3 big:mb-5">
            <el-collapse
              v-model="accordionModel"
              accordion
              class="px-2 bg-transparent"
            >
              <el-collapse-item
                :name="gaLoginMethods.goat"
                :disabled="!!(forgotPasswordMode || registerMode).length"
                class="gs-goat bg-gs-bg-gradient-from rounded-2xl px-2 bg-gradient-to-b from-gs-bg-gradient-from to-gs-bg-gradient-to"
                style="--el-color-item: white;"
                :icon="ArrowRightBold"
              >
                <template #title>
                  <div class="text-white text-left leading-none">
                    <div
                      v-if="!(forgotPasswordMode || registerMode).length"
                      class="text-xl font-semibold leading-none"
                    >
                      GOAT account<span class="text-xl -translate-y-2">*</span>
                    </div>
                    <div
                      v-if="!forgotPasswordMode.length && registerMode !== RegisterModeActions.confirmEmail && registerMode !== RegisterModeActions.acceptTerms"
                      class="font-light big:text-[1rem]"
                    >
                      One account for all games. See <span
                        class="underline underline-offset-4 cursor-pointer"
                        @click.stop="benefitDialogVisibility = true"
                      >benefits</span>.
                    </div>
                  </div>
                </template>
                <div
                  id="auth-goat-wrapper"
                  class="relative"
                >
                  <transition name="gs-fade">
                    <GsGoatLoginBox
                      v-if="accordionModel === gaLoginMethods.goat"
                      :buy-mode="buyMode"
                    />
                  </transition>
                </div>
              </el-collapse-item>
              <el-collapse-item
                v-if="!(forgotPasswordMode || registerMode).length && !canBeUpgraded"
                :name="gaLoginMethods.gameToken"
                class="rounded-2xl px-2 bg-gs-game-primary mt-4"
                :icon="ArrowRightBold"
              >
                <template #title>
                  <div class="text-left text-gray-600 leading-none">
                    <div class="text-xl leading-none font-semibold">
                      Game account
                    </div>
                    <div class="font-light big:text-[1rem]">
                      Connect game directly. No benefits.
                    </div>
                  </div>
                  <!--                    <el-button -->
                  <!--                      v-if="accordionModel === gaLoginMethods.gameToken && !(forgotPasswordMode || registerMode).length" -->
                  <!--                      type="primary" -->
                  <!--                      link -->
                  <!--                      class="absolute right-0 -bottom-1 z-10" -->
                  <!--                      size="large" -->
                  <!--                      @click.stop="showHowMode = true" -->
                  <!--                    > -->
                  <!--                      <icon-ify -->
                  <!--                        icon="mynaui:info-square" -->
                  <!--                        class="inline mr-1" -->
                  <!--                      /> -->
                  <!--                      see more -->
                  <!--                    </el-button> -->
                </template>
                <transition name="gs-fade">
                  <GsGameLoginBox
                    v-if="accordionModel === gaLoginMethods.gameToken"
                    :deep-link-url="deepLinkUrl"
                    :is-open-from-deep-link="isOpenFromDeepLink"
                    :buy-mode="buyMode"
                  />
                </transition>
              </el-collapse-item>
              <el-collapse-item
                v-if="deepLinkUrl.length && isMobileDevice && !isOpenFromDeepLink && !(forgotPasswordMode || registerMode).length && !canBeUpgraded"
                :name="gaLoginMethods.deepLink"
                :disabled="!!(forgotPasswordMode || registerMode).length"
                class="rounded-2xl px-2 bg-gs-game-primary mt-4"
                :icon="ArrowRightBold"
              >
                <template #title>
                  <div class="text-left text-gray-600 leading-none">
                    <div class="text-xl leading-none font-semibold py-1.5">
                      App
                    </div>
                  </div>
                </template>
                <transition name="gs-fade">
                  <GsAppLoginBox
                    v-if="accordionModel === gaLoginMethods.deepLink"
                    :deep-link-url="deepLinkUrl"
                  />
                </transition>
              </el-collapse-item>
            </el-collapse>
            <div
              v-if="!(forgotPasswordMode || registerMode).length && !canBeUpgraded"
              class="mt-3"
            >
              Learn more about: <br>
              <el-button
                link
                @click="accordionModel = gaLoginMethods.goat ;showHowMode = true"
              >
                GOAT account
              </el-button>
              |
              <el-button
                link
                class="ml-0"
                @click="accordionModel = gaLoginMethods.gameToken ;showHowMode = true"
              >
                Game account
              </el-button>
              <template v-if="deepLinkUrl.length && isMobileDevice && !isOpenFromDeepLink">
                |
                <el-button
                  link
                  class="ml-0"
                  @click="accordionModel = gaLoginMethods.deepLink ;showHowMode = true"
                >
                  App login
                </el-button>
              </template>
            </div>
          </div>
        </template>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="postcss">
.gs-login-dialog {
  --el-message-close-size: 1.5rem;
  --el-dialog-padding-primary: 0.1;
  .el-dialog {
    &__header {
      margin-right: 0 !important;
    }
  }
  .el-collapse {
    --el-collapse-header-bg-color: transparent;
    --el-collapse-content-bg-color: transparent;
    --el-collapse-header-height: auto;
    &-item{
      &__header {
        position: relative;
        padding: 1rem 0.4rem;
        border-style: none !important;
        max-width: 28rem !important;
        margin-inline: auto !important;
        &.is-active {
          padding-bottom: 0.5rem;
        }
      }
      &__arrow {
        --color: white;
        font-size: 1.2rem;
        transform-origin: center;
        margin-right: 0;
        margin-top: 0;
      }
      &__wrap {
        border-style: none !important;
      }
      &__content {
        border-style: none !important;
      }
      &.is-disabled {
        .el-collapse-item{
          &__header {
            padding-bottom: 0;
          }
          &__arrow {
            display: none;
          }
        }
      }
      &.gs-goat:not(.is-active) {
        background-image: none !important;
      }
    }
  }
  .el-image-viewer__actions {
    display: none !important;
  }
  .el-collapse-item.is-disabled .el-collapse-item__header {
    cursor: text !important;
  }
  .auth-wrapper .el-collapse-item__header {
    position: relative;
  }
  .auth-wrapper .el-form {
    --el-component-size-large: 44px;
    --el-border-radius-base: 6px;
    --el-border-color: #5a5a5a;
    .el-button {
      --el-button-size: 50px;
    }
    .el-form-item {
      position: relative;
      .el-form-item__label {
        padding-left: 5px;
        position: absolute;
        top: -15px;
        left: 0;
        margin:0;
        font-size: 0.6em;
        color: var(--el-color-item);
      }
      &.is-error {
        .el-input__wrapper, .el-checkbox__inner  {
          background-color: var(--el-color-danger-light-9);
          box-shadow: none;
          border: none;
        }
        .el-form-item__error, .el-form-item__label, .el-checkbox__label > span {
          color: var(--el-color-danger) !important;
        }
      }
    }
    .el-input__wrapper {
      background-color: #f7f7f7;
    }
  }
}
@media (min-width: theme('screens.big')) {
  .gs-login-dialog {
    .auth-wrapper .el-form {
      --el-component-size-large: 56px;

      .el-button {
        --el-button-size: 54px;
      }

      .el-form-item {
        .el-form-item__label {
          top: -15px;
          font-size: 0.8em;
        }
      }
    }
  }
}
</style>
