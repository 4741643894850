<script setup lang="ts">
import {
  closeLoginDialog,
  confirmedEmail,
  decodeGoatToken,
  getLoginGaContext,
  oldTokens,
  registerMode,
  RegisterModeActions,
  serviceName,
  serviceToken
} from '@/components/layout/LoginDialog/LoginDialog'
import { contextSection, visibilitySelectorGame } from '@/components/layout/SelectorGame/SelectorGameCompose'
import { confirmPasswordValidator, onChangePassword, passwordValidator } from '@/compositions/validators'
import { ElMessage, ElNotification } from 'element-plus'

interface Props {
  buyMode?: boolean
}
const props = defineProps<Props>()

const loading = {
  ref: ref('')
}
const { triggerApi, checkBuyOfferWithApi, goatDebugOldTokens, goatConnectionSkippedGames } = gamesUtils
const { gaLoginMethods, gaLogin, gaSignUpMethods } = gaUtils
function handleRegisterResponse(status: string, token: string, email: string, socialServiceName = '', skipped: string[] = []) {
  switch (status) {
    case 'ok':
    // when social register and account exist;
    { const decodedToken = decodeGoatToken(token, email)
      if (decodedToken?.token?.length) {
        if (!props.buyMode) {
          ElMessage({
            message: 'Logged in',
            type: 'success',
            duration: 3000,
            offset: 10
          })
          triggerApi.callTrigger()
          goatConnectionSkippedGames(skipped)
        }
        const gaLoginContext = getLoginGaContext()
        gaLoginContext.method = `${gaLoginMethods.goat}${socialServiceName?.length ? ',' : ''}${socialServiceName}`
        gaLogin(gaLoginContext)
        visibilitySelectorGame.value = false
        contextSection.value = ''
        closeLoginDialog()
        checkBuyOfferWithApi(loading.ref)
      } else {
        ElNotification({
          title: 'Sorry, but logging in is currently unavailable.',
          message: 'Contact us if the problem persists.',
          type: 'error',
          duration: 9000,
          offset: 100,
          appendTo: '#auth-goat-wrapper',
          customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
        })
      }

      break }
    case 'confirm_email':
      ElNotification({
        title: 'Just one more step to go',
        message: 'We’ve sent a verification email to the address provided. Once verified, you’ll be able to log in.',
        type: 'success',
        duration: 0,
        offset: 50,
        appendTo: '#auth-goat-wrapper',
        customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
      })
      confirmedEmail.value = email
      registerMode.value = RegisterModeActions.confirmEmail
      break
    case 'in_use':
      ElNotification({
        title: 'Registration is unavailable',
        message: 'The email you entered is already registered. Please try logging in.',
        type: 'warning',
        offset: 100,
        duration: 9000,
        appendTo: '#auth-goat-wrapper',
        customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
      })
      break
    case 'accept_terms':
      if (token && socialServiceName) {
        serviceName.value = socialServiceName
        serviceToken.value = token
        registerMode.value = RegisterModeActions.acceptTerms
      } else {
        ElNotification({
          title: 'Registration is unavailable',
          message: 'Contact us if the problem persists (accept terms required).',
          type: 'error',
          duration: 9000,
          offset: 100,
          appendTo: '#auth-goat-wrapper',
          customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
        })
      }
      break
    case 'missing_email':
      ElNotification({
        title: `${(socialServiceName || '').charAt(0).toUpperCase() + (socialServiceName || '').slice(1)} register failed. Please register using the form.`,
        type: 'error',
        offset: 100,
        duration: 9000,
        appendTo: '#auth-goat-wrapper',
        customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
      })
      break
    default:
      ElNotification({
        title: 'Registration is unavailable',
        message: 'Contact us if the problem persists.',
        type: 'error',
        duration: 9000,
        offset: 100,
        appendTo: '#auth-goat-wrapper',
        customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
      })
  }
}
function onSubmit(data: FormTypes.SubmitForm) {
  loading.ref.value = 'register'
  const { getGoatApiUrl, apiPost, catchFormErrors, GameKeyEnum } = gamesUtils
  ElNotification.closeAll()
  apiPost(GameKeyEnum.goat, getGoatApiUrl('register'), { ...data.form, oldTokens: oldTokens.value })
    .then((response) => {
      goatDebugOldTokens(oldTokens.value)
      handleRegisterResponse(
        response?.data?.status || '',
        response?.data?.token ?? '',
        response?.data?.email || data.form?.email || '',
        '',
        response?.data?.skipped || []
      )
    })
    .catch(catchFormErrors(data.externalErrors, { appendTo: '#auth-goat-wrapper' }))
    .then(() => {
      loading.ref.value = ''
    })
}
function handleGoogleCredentialResponse(token: string) {
  const { getGoatApiUrl, apiPost, catchNotifyError, GameKeyEnum, clearGameTokens } = gamesUtils
  clearGameTokens()
  ElNotification.closeAll()
  apiPost(GameKeyEnum.goat, getGoatApiUrl('gmail-login'), { token, oldTokens: oldTokens.value })
    .then((response) => {
      goatDebugOldTokens(oldTokens.value)
      handleRegisterResponse(
        response?.data?.status || '',
        response?.data?.token ?? token,
        response?.data?.email ?? '',
        gaSignUpMethods.gmail,
        response?.data?.skipped || []
      )
    })
    .catch(catchNotifyError({ appendTo: '#auth-goat-wrapper' }))
}
function handleFbCredentialResponse(token: string) {
  const { getGoatApiUrl, apiPost, catchNotifyError, GameKeyEnum, clearGameTokens } = gamesUtils
  clearGameTokens()
  ElNotification.closeAll()
  apiPost(GameKeyEnum.goat, getGoatApiUrl('facebook-login'), { token, oldTokens: oldTokens.value })
    .then((response) => {
      goatDebugOldTokens(oldTokens.value)
      handleRegisterResponse(
        response?.data?.status || '',
        response?.data?.token ?? token,
        response?.data?.email ?? '',
        gaSignUpMethods.facebook,
        response?.data?.skipped || []
      )
    })
    .catch(catchNotifyError({ appendTo: '#auth-goat-wrapper' }))
}
// const isEnabledFb = import.meta.env.VITE_PROD !== 'on' || !!localStorage?.getItem('isEnabledFb')
const isEnabledFb = true
</script>

<template>
  <GsFormComponent
    class="mt-2 big:mb-5 w-full"
    size="large"
    hide-required-asterisk
    @submit="onSubmit"
  >
    <template #default="{ submit, form, formRefer }">
      <GsFieldTextTrim
        v-focus
        api-field-name="email"
        placeholder="Email"
        :label="!!form?.email?.length"
        class="mt-1 big:mt-1.5 text-lg"
        required
        :rules="[{ type: 'email', trigger: 'blur', message: 'Not valid email' }]"
        @keyup.enter.stop.prevent="submit"
      />
      <GsFieldTextTrim
        api-field-name="password"
        placeholder="Password"
        :label="!!form?.password?.length"
        class="mt-1 big:mt-1.5 text-lg"
        type="password"
        show-password
        :rules="[passwordValidator()]"
        @input="onChangePassword(form, formRefer)"
        @keyup.enter.stop.prevent="submit"
      />
      <GsFieldTextTrim
        api-field-name="confirmPassword"
        placeholder="Confirm password"
        :label="!!form?.confirmPassword?.length"
        class="mt-1 big:mt-1.5 text-lg"
        type="password"
        show-password
        :rules="[confirmPasswordValidator(form)]"
        @keyup.enter.stop.prevent="submit"
      />
      <GsGoatAcceptTermsCheckBoxes />
      <GsFormFieldsGroup
        justify="center"
      >
        <el-button
          type="primary"
          class="text-white rounded-lg uppercase text-lg w-[47%] font-semibold gs-loading"
          size="large"
          :loading="loading.ref.value === 'register'"
          @click="submit()"
        >
          Sign up
        </el-button>
      </GsFormFieldsGroup>
      <GsFormFieldsGroup
        justify="center"
        class="mt-5 text-white text-sm font-light big:text-[1rem]"
      >
        Already have a GOAT account?
        <el-button
          link
          class="text-white rounded-lg"
          size="large"
          @click="registerMode = ''"
        >
          <span class="underline underline-offset-2 font-light ml-1 big:text-[1rem]">Log in here.</span>
        </el-button>
        <GsFormFieldsGroup>
          <div class="flex justify-center items-center w-full mt-5">
            <GoogleCredentials :call-back="handleGoogleCredentialResponse" />
          </div>
        </GsFormFieldsGroup>
        <GsFormFieldsGroup v-if="isEnabledFb">
          <div class="flex justify-center items-center w-full">
            <FbCredentials :call-back="handleFbCredentialResponse" />
          </div>
        </GsFormFieldsGroup>
      </gsformfieldsgroup>
    </template>
  </GsFormComponent>
</template>

<style scoped lang="postcss">

</style>
