<script setup lang="ts">
import {
  closeLoginDialog,
  confirmedEmail,
  decodeGoatToken,
  forgotPasswordMode,
  ForgotPasswordSteps,
  getLoginGaContext,
  oldTokens,
  registerMode,
  RegisterModeActions,
  serviceName,
  serviceToken
} from '@/components/layout/LoginDialog/LoginDialog'
import { contextSection, visibilitySelectorGame } from '@/components/layout/SelectorGame/SelectorGameCompose'
import { ElMessage, ElNotification } from 'element-plus'

const props = defineProps<Props>()

interface Props {
  buyMode?: boolean
}
const loading = {
  ref: ref('')
}
const ban = ref(false)
const { checkBuyOfferWithApi, triggerApi, goatDebugOldTokens, goatConnectionSkippedGames } = gamesUtils
const { gaLoginMethods, gaLogin, gaSignUpMethods } = gaUtils
function handleLoginResponse(status: string, token: string, email: string, socialServiceName = '', skipped: string[] = []) {
  switch (status) {
    case 'ok':
    { const decodedToken = decodeGoatToken(token, email)
      if (decodedToken?.token?.length) {
        if (!props.buyMode) {
          ElMessage({
            message: 'Logged in',
            type: 'success',
            duration: 3000,
            offset: 10
          })
          triggerApi.callTrigger()
          goatConnectionSkippedGames(skipped)
        }
        const gaLoginContext = getLoginGaContext()
        gaLoginContext.method =
            gaLoginContext?.method === gaLoginMethods.deepLink
              ? `${gaLoginMethods.deepLink},${gaLoginMethods.goat}${socialServiceName?.length ? ',' : ''}${socialServiceName}`
              : `${gaLoginMethods.goat}${socialServiceName?.length ? ',' : ''}${socialServiceName}`
        gaLogin(gaLoginContext)
        visibilitySelectorGame.value = false
        contextSection.value = ''
        closeLoginDialog()
        checkBuyOfferWithApi(loading.ref)
      } else {
        ElNotification({
          title: 'Sorry, but logging in is currently unavailable.',
          message: 'Contact us if the problem persists.',
          type: 'error',
          duration: 9000,
          offset: 100,
          appendTo: '#auth-goat-wrapper',
          customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
        })
      }

      break }
    case 'confirm_email':
      ElNotification({
        title: 'Your email has not been verified',
        message: 'We\'ve sent a verification message to your address. Please complete the verification first. If you don\'t see the email, try registering again.',
        type: 'warning',
        duration: 0,
        offset: 20,
        appendTo: '#auth-goat-wrapper',
        customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
      })
      confirmedEmail.value = email
      registerMode.value = RegisterModeActions.confirmEmail
      break
    case 'invalid_email_password':
      ElNotification({
        title: 'Invalid login credentials',
        type: 'error',
        offset: 100,
        duration: 9000,
        appendTo: '#auth-goat-wrapper',
        customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
      })
      break
    case 'to_many_tries':
      ban.value = true
      ElNotification({
        title: 'Too many failed login attempts.',
        message: 'Try again in 10 seconds.',
        type: 'error',
        duration: 10000,
        offset: 100,
        showClose: false,
        appendTo: '#auth-goat-wrapper',
        customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full',
        onClose: () => ban.value = false
      })
      break
    case 'accept_terms':
      if (token && socialServiceName) {
        serviceName.value = socialServiceName
        serviceToken.value = token
        registerMode.value = RegisterModeActions.acceptTerms
      } else {
        ElNotification({
          title: 'Registration is unavailable',
          message: 'Contact us if the problem persists (accept terms required).',
          type: 'error',
          duration: 9000,
          offset: 100,
          appendTo: '#auth-goat-wrapper',
          customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
        })
      }
      break
    case 'missing_email':
      ElNotification({
        title: `${(socialServiceName || '').charAt(0).toUpperCase() + (socialServiceName || '').slice(1)} login failed. Please log in using the form.`,
        type: 'error',
        offset: 100,
        duration: 9000,
        appendTo: '#auth-goat-wrapper',
        customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
      })
      break
    default:
      ElNotification({
        title: 'Login is currently unavailable',
        message: 'Contact us if the problem persists.',
        type: 'error',
        duration: 9000,
        offset: 100,
        appendTo: '#auth-goat-wrapper',
        customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
      })
  }
}

function onSubmit(data: FormTypes.SubmitForm) {
  loading.ref.value = 'logInDialog'
  const { getGoatApiUrl, apiPost, catchFormErrors, GameKeyEnum } = gamesUtils
  ElNotification.closeAll()
  apiPost(GameKeyEnum.goat, getGoatApiUrl('login'), { ...data.form, oldTokens: oldTokens.value }).then((response) => {
    goatDebugOldTokens(oldTokens.value)
    handleLoginResponse(
      response?.data?.status || '',
      response?.data?.token ?? '',
      response?.data?.email || data?.form?.email || '',
      '',
      response?.data?.skipped || []
    )
  }).catch(catchFormErrors(data.externalErrors, { appendTo: '#auth-goat-wrapper' })).then(() => {
    loading.ref.value = ''
  })
}

function handleGoogleCredentialResponse(token: string) {
  const { getGoatApiUrl, apiPost, catchNotifyError, GameKeyEnum } = gamesUtils
  ElNotification.closeAll()
  apiPost(GameKeyEnum.goat, getGoatApiUrl('gmail-login'), { token, oldTokens: oldTokens.value })
    .then((response) => {
      goatDebugOldTokens(oldTokens.value)
      handleLoginResponse(
        response?.data?.status || '',
        response?.data?.token ?? token,
        response?.data?.email ?? '',
        gaSignUpMethods.gmail,
        response?.data?.skipped || []
      )
    })
    .catch(catchNotifyError({ appendTo: '#auth-goat-wrapper' }))
}
function handleFbCredentialResponse(token: string) {
  const { getGoatApiUrl, apiPost, catchNotifyError, GameKeyEnum } = gamesUtils
  ElNotification.closeAll()
  apiPost(GameKeyEnum.goat, getGoatApiUrl('facebook-login'), { token, oldTokens: oldTokens.value })
    .then((response) => {
      goatDebugOldTokens(oldTokens.value)
      handleLoginResponse(
        response?.data?.status || '',
        response?.data?.token ?? token,
        response?.data?.email ?? '',
        gaSignUpMethods.facebook,
        response?.data?.skipped || []
      )
    })
    .catch(catchNotifyError({ appendTo: '#auth-goat-wrapper' }))
}
onUnmounted(() => {
  registerMode.value = ''
  confirmedEmail.value = ''
  serviceName.value = ''
  serviceToken.value = ''
})
// const isEnabledFb = import.meta.env.VITE_PROD !== 'on' || !!localStorage?.getItem('isEnabledFb')
const isEnabledFb = true
</script>

<template>
  <div class="login-form-wrapper mx-auto max-w-2xl big:max-w-screen-xl mb-2 pt-2">
    <div
      class="login-form flex flex-col items-center"
    >
      <div class="mx-auto w-full max-w-md">
        <GsGoatForgotPassword v-if="forgotPasswordMode" />
        <GsGoatRegisterBox
          v-else-if="registerMode === RegisterModeActions.register"
        />
        <GsGoatConfirmEmail v-else-if="registerMode === RegisterModeActions.confirmEmail" />
        <GsGoatAcceptTermsBox
          v-else-if="registerMode === RegisterModeActions.acceptTerms"
          :buy-mode="buyMode"
        />
        <GsFormComponent
          v-else
          class="w-full"
          size="large"
          hide-required-asterisk
          @submit="onSubmit"
        >
          <template #default="{ submit, form }">
            <GsFieldTextTrim
              v-focus
              api-field-name="email"
              placeholder="Email"
              :label="!!form?.email?.length"
              class="mt-1 text-lg"
              required
              :rules="[{ type: 'email', trigger: 'blur', message: 'Not valid email' }]"
              @keyup.enter.stop.prevent="submit"
            />
            <el-row
              class="px-0 w-full relative mb-3"
            >
              <GsFieldTextTrim
                api-field-name="password"
                placeholder="Password"
                :label="!!form?.password?.length"
                class="mt-1 big:mt-1.5 text-lg"
                type="password"
                show-password
                :rules="[{ type: 'string', trigger: 'blur', min: 6, message: 'At least 6 characters' }]"
                required
                @keyup.enter.stop.prevent="submit"
              />
              <el-button
                link
                class="absolute -bottom-0.5 right-1"
                size="small"
                @click="forgotPasswordMode = ForgotPasswordSteps.step1"
              >
                <span class="text-white underline underline-offset-4 font-light big:text-[0.8rem]">Forgot Password?</span>
              </el-button>
            </el-row>
            <GsFormFieldsGroup
              justify="center"
            >
              <el-button
                type="primary"
                class="text-white rounded-lg uppercase text-lg w-full max-w-44 mt-2 font-semibold gs-loading"
                size="large"
                :loading="loading.ref.value === 'logInDialog'"
                :disabled="ban"
                @click="submit()"
              >
                login
              </el-button>
            </GsFormFieldsGroup>
            <GsFormFieldsGroup
              justify="center"
              class="mt-5 text-white text-sm font-light big:text-[1rem]"
            >
              Don't have an account?
              <el-button
                link
                class="text-white rounded-lg"
                size="large"
                @click="registerMode = RegisterModeActions.register"
              >
                <span class="underline underline-offset-2 font-light ml-1 big:text-[1rem]">Sign up here</span>
              </el-button>
            </GsFormFieldsGroup>
            <GsFormFieldsGroup>
              <div class="flex justify-center items-center w-full mt-5">
                <GoogleCredentials :call-back="handleGoogleCredentialResponse" />
              </div>
            </GsFormFieldsGroup>
            <GsFormFieldsGroup v-if="isEnabledFb">
              <div class="flex justify-center items-center w-full">
                <FbCredentials :call-back="handleFbCredentialResponse" />
              </div>
            </GsFormFieldsGroup>
          </template>
        </GsFormComponent>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.login-form-wrapper:deep(.el-input__count-inner) {
    background-color: transparent;
}
</style>
