<script setup lang="ts">
import type { BasicPropsType, NumberPropsType, ResponsivePropsType } from '@/components/form/compositions/FieldsUtils'
import {

  BasicPropsDefaults,
  convertApiNamesToHuman,
  gatByPath,
  getForm,
  NumberPropsDefaults,
  ResponsivePropsDefaults,
  setByPath

} from '@/components/form/compositions/FieldsUtils'

import { checkNumberValidator } from '@/compositions/validators'

const props = withDefaults(defineProps<BasicPropsType & NumberPropsType & ResponsivePropsType>(), {
  ...BasicPropsDefaults,
  ...NumberPropsDefaults,
  ...ResponsivePropsDefaults
})

const gatByPathUtil = gatByPath

const fieldRules = computed(() => {
  const rules = [...props.rules]
  if (props.required) {
    rules.push({ required: true, message: 'required', trigger: 'change' })
  }
  if (props.number) {
    rules.push({ validator: checkNumberValidator, trigger: 'change' })
  }
  return rules
})

const itemLabel = computed(() => {
  if (props.label === false || props.label === '') {
    return ''
  }
  if (props.label === undefined || props.label === true) {
    return convertApiNamesToHuman(props.apiFieldName || '')
  }
  return String(props.label)
})

const fieldNamePath = computed(() => {
  return (props.apiFieldName || '').split('.')
})

const crudExternalErrors = props.externalErrors || inject('crudExternalErrors') || ref({})
const crudForm = getForm(props.form)
crudExternalErrors.value[props.apiFieldName] = ''

const fieldValue = computed(() => {
  return gatByPathUtil(crudForm.value, fieldNamePath.value)
})
if (props.initValue !== undefined && fieldValue.value === undefined) {
  setByPath(crudForm.value, fieldNamePath.value, props.initValue)
}
</script>

<template>
  <el-col
    :xs="xs"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
    :class="[paddingClass]"
  >
    <el-form-item
      class="grow max-w-full"
      :label="itemLabel"
      :prop="apiFieldName"
      :rules="fieldRules"
      :error="gatByPathUtil(crudExternalErrors, fieldNamePath)"
      :size="size"
    >
      <slot
        name="default"
        :field-value="fieldValue"
        :api-field-name="apiFieldName"
        :api-field-human="convertApiNamesToHuman(apiFieldName || '')"
        :item-label="itemLabel"
        :field-rules="fieldRules"
        :form="crudForm"
        :external-errors="crudExternalErrors"
      />
    </el-form-item>
  </el-col>
</template>

<style scoped lang="postcss">

</style>
