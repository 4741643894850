<script setup lang="ts">
import {
  closeLoginDialog,
  decodeGoatToken,
  getLoginGaContext,
  oldTokens,
  registerMode,
  RegisterModeActions,
  serviceName,
  serviceToken
} from '@/components/layout/LoginDialog/LoginDialog'
import { contextSection, visibilitySelectorGame } from '@/components/layout/SelectorGame/SelectorGameCompose'
import { ElMessage, ElNotification } from 'element-plus'

interface Props {
  buyMode?: boolean
}
const props = defineProps<Props>()

const { checkBuyOfferWithApi, triggerApi } = gamesUtils
const { gaLoginMethods, gaLogin, gaSignUp } = gaUtils
const loading = {
  ref: ref('')
}

function onSubmit(data: FormTypes.SubmitForm) {
  loading.ref.value = 'acceptTerms'
  const { getGoatApiUrl, apiPost, catchNotifyError, GameKeyEnum, goatDebugOldTokens, goatConnectionSkippedGames } = gamesUtils
  ElNotification.closeAll()
  apiPost(GameKeyEnum.goat, getGoatApiUrl(`${serviceName.value}-login`), { ...data.form, token: serviceToken.value || '', oldTokens: oldTokens.value }).then().then((response) => {
    switch (response?.data?.status || '') {
      case 'ok':
      {
        goatDebugOldTokens(oldTokens.value)
        const decodedToken = decodeGoatToken(response?.data?.token ?? '', response?.data?.email ?? '')
        if (decodedToken?.token?.length) {
          if (!props.buyMode) {
            ElMessage({
              message: 'Logged in',
              type: 'success',
              duration: 3000,
              offset: 10
            })
            triggerApi.callTrigger()
            goatConnectionSkippedGames(response?.data?.skipped || [])
          }
          const gaLoginContext = getLoginGaContext()
          gaLoginContext.method =
              gaLoginContext?.method === gaLoginMethods.deepLink
                ? `${gaLoginMethods.deepLink}, ${serviceName.value || gaLoginContext?.method || gaLoginMethods.goat}`
                : serviceName.value || gaLoginContext?.method || gaLoginMethods.goat

          gaSignUp({ ...gaLoginContext, gs_context: `${gaLoginContext?.gs_context || ''}${gaLoginContext?.gs_context?.length ? ',' : ''}${data?.form?.acceptCommunication ? 'acceptCommunication=true' : 'acceptCommunication=false'}` })
          gaLogin(gaLoginContext)
          visibilitySelectorGame.value = false
          contextSection.value = ''
          closeLoginDialog()
          checkBuyOfferWithApi(loading.ref)
        } else {
          ElNotification({
            title: 'Sorry, but logging in is currently unavailable.',
            message: 'Contact us if the problem persists.',
            type: 'error',
            duration: 9000,
            offset: 100,
            appendTo: '#auth-goat-wrapper',
            customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
          })
        }

        break }
      default:
        ElNotification({
          title: 'Confirm consents is currently unavailable',
          message: 'Contact us if the problem persists.',
          type: 'error',
          duration: 9000,
          offset: 100,
          appendTo: '#auth-wrapper',
          customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
        })
    }
  }).catch(catchNotifyError({ appendTo: '#auth-goat-wrapper' })).then(() => {
    loading.ref.value = ''
  })
}

onUnmounted(() => {
  serviceName.value = ''
  serviceToken.value = ''
})
</script>

<template>
  <GsFormComponent
    class="w-full"
    size="large"
    hide-required-asterisk
    @submit="onSubmit"
  >
    <template #default="{ submit }">
      <GsGoatAcceptTermsCheckBoxes />
      <GsFormFieldsGroup
        justify="space-between"
      >
        <el-button
          type="info"
          class="text-neutral-600 rounded-lg uppercase text-lg w-[46%] font-semibold"
          @click="registerMode = RegisterModeActions.register"
        >
          cancel
        </el-button>
        <el-button
          type="primary"
          class="text-white rounded-lg uppercase text-lg w-[46%] font-semibold gs-loading"
          size="large"
          :loading="loading.ref.value === 'acceptTerms'"
          @click="submit()"
        >
          confirm
        </el-button>
      </GsFormFieldsGroup>
    </template>
  </GsFormComponent>
</template>

<style scoped lang="postcss">

</style>
