<script setup lang="ts">
import { ElNotification } from 'element-plus'

interface Props {
  clientID?: string
  action?: string
  callBack: (token: string) => void
}
const props = withDefaults(defineProps<Props>(), {
  clientID: '1106063917803963',
  action: 'Login'
})
function handleFbCredentialResponse(response: any) {
  ElNotification.closeAll()
  if (response?.status === 'connected' && response?.authResponse?.accessToken?.length > 0) {
    props.callBack(response.authResponse.accessToken)
  } else {
    ElNotification({
      title: `${props.action} is unavailable`,
      message: 'Contact us if the problem persists.',
      type: 'error',
      duration: 9000,
      offset: 100,
      appendTo: '#auth-goat-wrapper',
      customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
    })
  }
}
function handleLogin() {
  window.FB.login(
    (response) => {
      if (response?.status === 'connected' && response?.authResponse?.accessToken) {
        props.callBack(response.authResponse.accessToken)
      } else if (response?.status !== 'unknown') {
        ElNotification({
          title: 'Facebook login failed. Please try again.',
          type: 'error',
          duration: 9000,
          offset: 100,
          appendTo: '#auth-goat-wrapper',
          customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
        })
      }
    },
    { scope: 'email,public_profile' }
  )
}
onMounted(() => {
  (window as any).handleFbCredentialResponse = handleFbCredentialResponse
  if (!window.FB) {
    const script = document.createElement('script')
    script.id = 'facebook-jssdk'
    script.src = 'https://connect.facebook.net/en_US/sdk.js'
    script.async = true
    script.defer = true

    script.onload = () => {
      const config = {
        appId: props.clientID,
        cookie: true,
        xfbml: true,
        version: 'v21.0'
      }
      window.FB.init(config)
    }
    document.head.appendChild(script)
  }
})
</script>

<template>
  <div class="w-[240px] big:w-[270px] big:px-[5px]">
    <div
      class="bg-[#3c5a95] text-white pl-2 py-0.5 pr-0.5 rounded h-10 grid grid-cols-[1fr,auto] items-center cursor-pointer hover:opacity-90 active:opacity-95"
      @click="handleLogin"
    >
      <div class="text-[16px] font-medium text-center text-ellipsis overflow-hidden whitespace-nowrap">
        Sign in with Facebook
      </div>
      <icon-ify
        icon="fa:facebook-official"
        class="size-[36px] big:size-[40px]"
      />
    </div>
  </div>
</template>

<style scoped lang="postcss">

</style>
