<script setup lang="ts">
import { onMountedApp } from '@/compositions/onMountedApp'

const { triggerApi, isLoggedGoat } = gamesUtils
triggerApi.initTriggerApi()
const route = useRoute()
onMounted(() => {
  if (route.query.forceVisibility) {
    // forceVisibility=soccer-clash
    localStorage?.setItem?.('id-visible', String(route.query.forceVisibility))
    delete route.query.forceVisibility
    window.location.href = route.path
  }

  if (route.query.devtimeout) {
    localStorage?.setItem?.('devtimeout', String(route.query.devtimeout === '0' ? '' : route.query.devtimeout))
    delete route.query.devtimeout
    window.location.href = route.path
  }
  onMountedApp()
})
</script>

<template>
  <install-prompt-component />
  <page-header />
  <router-view />
  <page-footer />
  <log-in-dialog />
  <offer-dialog-component />
  <pay-pro-component />
  <buy-free-dialog-component />
  <payment-method-component />
  <selector-game-component />
  <manage-cookies-component />
  <layout-page-loader-component />
  <goat-benefit-dialog />
  <template v-if="isLoggedGoat">
    <goat-user-box-dialog />
    <goat-connect-game-dialog />
  </template>
  <cart-drawer-component />
</template>

<style scoped></style>
