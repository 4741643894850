<script setup lang="ts">
import { openCartDrawer } from '@/components/games/cartDrawer/CartDrawerCompose'

defineProps<Props>()
const { routeGame, isLogged } = gamesUtils
const { isDesktop } = tools
interface Props {
  iconClass?: string
}
const { gaSections } = gaUtils
function onClick() {
  openCartDrawer(routeGame.value?.gameKey, {
    gs_game: routeGame.value?.gameKey,
    gs_route: String(utilsPlugin.getApp()?.$route?.name),
    gs_section: `${gaSections.header}, ${gaSections.cart}`
  })
}

const gameCart = computed(() => {
  const _gameCart: Games.Offer[] = utilsPlugin
    .getApp()
    ?.$store
    ?.getters?.['game/gameCart']?.(routeGame.value?.gameKey)
  return _gameCart
})
</script>

<template>
  <div v-if="routeGame && isLogged && routeGame.enableCart">
    <el-badge
      :value="gameCart.length"
      :offset="[isDesktop ? -15 : 2, 0]"
      :show-zero="false"
      class="cursor-pointer"
      @click="onClick"
    >
      <icon-ify
        icon="tdesign:cart"
        class="inline hover:text-cyan-600 active:text-cyan-500 cursor-pointer"
        :class="iconClass"
      />
    </el-badge>
  </div>
</template>

<style scoped lang="postcss"></style>
