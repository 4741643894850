<script setup lang="ts">
import { closeGoatUserBoxDialog, goatUserBoxGameKey, goatUserBoxVisibility, openFromSection } from '@/components/layout/GoatUserBoxDialog/GoatUserBoxDialog'

const { isDesktop } = tools
onUnmounted(() => {
  closeGoatUserBoxDialog()
})
</script>

<template>
  <el-dialog
    v-model="goatUserBoxVisibility"
    class="p-0 bg-transparent"
    :show-close="true"
    width="min(95vw, 580px)"
    modal-class="bg-black/90"
    :top="isDesktop ? undefined : '1vh'"
    :center="isDesktop"
    @close="closeGoatUserBoxDialog"
  >
    <goat-user-box
      v-if="goatUserBoxGameKey"
      :game-key="goatUserBoxGameKey"
      modal-mode
      :open-from-section="openFromSection"
    />
  </el-dialog>
</template>

<style scoped lang="postcss">

</style>
