<script setup lang="ts">
import {
  confirmedEmail,
  getLoginGaContext,
  registerMode,
  RegisterModeActions
} from '@/components/layout/LoginDialog/LoginDialog'
import { ElNotification } from 'element-plus'

const loading = ref(false)
const { gaSignUpMethods, gaSignUp } = gaUtils
function onSubmitConfirmEmail(data: FormTypes.SubmitForm) {
  loading.value = true
  const { getGoatApiUrl, apiPost, catchFormErrors, GameKeyEnum } = gamesUtils
  ElNotification.closeAll()
  apiPost(GameKeyEnum.goat, getGoatApiUrl('confirm-email'), { ...data.form, email: confirmedEmail.value || '' })
    .then((response) => {
      switch (response?.data?.status || '') {
        case 'ok':
        { ElNotification({
          title: 'Email has been confirmed.',
          message: 'Now you can log in.',
          type: 'success',
          duration: 9000,
          offset: 100,
          appendTo: '#auth-goat-wrapper',
          customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
        })
        const gaLoginContext = { ...getLoginGaContext() }
        gaSignUp({
          ...gaLoginContext,
          method: gaSignUpMethods.goatForm || '',
          gs_context: `${gaLoginContext?.gs_context || ''}${gaLoginContext?.gs_context?.length ? ',' : ''}${response?.data?.acceptCommunication ? 'acceptCommunication=true' : 'acceptCommunication=false'}`,
          gs_user_id: response?.data?.id ? `goat_${response?.data?.id}` : ''
        })
        confirmedEmail.value = ''
        registerMode.value = ''
        break }
        case 'invalid_token':
          ElNotification({
            title: 'Invalid verification code',
            type: 'error',
            duration: 9000,
            offset: 100,
            appendTo: '#auth-goat-wrapper',
            customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
          })
          break
        default:
          ElNotification({
            title: 'Email verification is not available.',
            message: 'Contact us if the problem persists.',
            type: 'error',
            duration: 9000,
            offset: 100,
            appendTo: '#auth-goat-wrapper',
            customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
          })
      }
    })
    .catch(catchFormErrors(data.externalErrors, { appendTo: '#auth-wrapper' }))
    .then(() => {
      loading.value = false
    })
}

onUnmounted(() => {
  confirmedEmail.value = ''
})
</script>

<template>
  <GsFormComponent
    class=" w-full"
    size="large"
    hide-required-asterisk
    @submit="onSubmitConfirmEmail"
  >
    <template #default="{ submit, form }">
      <GsFieldTextTrim
        api-field-name="token"
        placeholder="Verification code"
        :label="!!form?.token?.length ? 'Verification code' : false"
        class="mt-1 big:mt-1.5 text-lg"
        required
        maxlength="4"
        show-word-limit
        :rules="[{ type: 'string', trigger: 'change', len: 4, message: '4 characters required' }]"
        @keyup.enter.stop.prevent="submit"
      />
      <GsFormFieldsGroup>
        <div class="mb-6 px-2 text-white text-base">
          To confirm your email address, enter the verification code found in the message we sent you.
        </div>
      </GsFormFieldsGroup>
      <GsFormFieldsGroup
        justify="space-between"
      >
        <el-button
          type="info"
          class="text-neutral-500 rounded-lg uppercase text-lg w-[46%] font-semibold"
          @click="registerMode = RegisterModeActions.register"
        >
          cancel
        </el-button>
        <el-button
          type="primary"
          class="text-white rounded-lg uppercase text-lg w-[46%] font-semibold gs-loading"
          size="large"
          :loading="loading"
          @click="submit()"
        >
          confirm
        </el-button>
      </GsFormFieldsGroup>
    </template>
  </GsFormComponent>
</template>

<style scoped lang="postcss">

</style>
