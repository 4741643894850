<script setup lang="ts">
import CheckdUrl from '@/assets/icon_check.png?url'
import CardUrl from '@/assets/loyalty/icon_card.png?url'
import GcoinUrl from '@/assets/loyalty/icon_gcoin.png?url'
import { openCartDrawer } from '@/components/games/cartDrawer/CartDrawerCompose'
import { visibilityPaymentMethod } from '@/components/layout/PaymentMethod/PaymentMethodCompose'
import { payPro } from '@/components/layout/PayPro/PayProCompose'
import { utilsPlugin } from '@/plugins/utilsPlugin'

const { buyOffer, showCheckout, loading, buyGCoins, addToGameCart, list, isLoggedGoat } = gamesUtils
const { isMobileDevice } = tools
const { gaAddToCart } = gaUtils

enum PaymentMethod {
  CART = 'CART',
  GCOIN = 'GCOIN',
  CASH = 'CASH',
  CART_CHECKOUT = 'CART_CHECKOUT'
}

const gameSettings = computed(() => {
  return list[buyOffer.value?.gameKey ?? ''] ?? {}
})
const gameCartMap = computed(() => {
  const _gameCartMap: Record<string | number, number> = utilsPlugin
    .getApp()
    ?.$store
    ?.getters?.['game/gameCartMap']?.(gameSettings.value?.gameKey)
  return _gameCartMap
})
const gameCart = computed(() => {
  const _gameCart: Games.Offer[] = utilsPlugin
    .getApp()
    ?.$store
    ?.getters?.['game/gameCart']?.(gameSettings.value?.gameKey)
  return _gameCart
})

const isAvailableGcoinMethod = computed(() => {
  if (buyOffer.value?.gcoinsPrice) {
    const gameLoyalty: Games.Loyalty | null = utilsPlugin
      .getApp()
      ?.$store
      ?.getters?.['game/loyalty'](buyOffer.value?.gameKey)
    if (gameLoyalty?.id && gameLoyalty?.gcoins >= buyOffer.value?.gcoinsPrice) {
      return true
    }
  }
  return false
})

const isVisibleGcoinMethod = computed(() => {
  return !!buyOffer.value?.gcoinsPrice
})

const canAddToCart = computed(() => {
  return !(
    gameCart.value.length >= utilsPlugin.getApp()?.$store?.getters['game/cartCapacity'] ||
    (buyOffer.value?.id &&
      gameCartMap.value[buyOffer.value.id] !== undefined &&
      (!buyOffer.value?.allowQty ||
        (buyOffer.value?.allowQty &&
          (buyOffer.value?.purchaseLimit ?? 0) <= (gameCart.value?.[gameCartMap.value?.[buyOffer.value.id]]?.qty ?? 0))))
  )
})

const selectedMethod = ref<PaymentMethod | ''>(PaymentMethod.CASH)
function onClosed() {
  selectedMethod.value = PaymentMethod.CASH
  payPro.loading.value = ''
  buyOffer.value = null
  loading.value = ''
}

function addToCart() {
  if (buyOffer.value) {
    addToGameCart(buyOffer.value)
    utilsPlugin.getApp()?.$notify({
      type: 'success',
      customClass: 'child-inherit-colors text-green-500 z-[999999]',
      message: 'Added to cart',
      duration: 3000,
      offset: 140
    })
    const gameCart: Games.Offer[] = utilsPlugin.getApp()?.$store?.getters?.['game/gameCart']?.(buyOffer.value?.gameKey) || []
    gaAddToCart(
      {
        currency: buyOffer.value?.price?.currency,
        value: buyOffer.value?.discount?.value || buyOffer.value?.price?.value || 0,
        items: [
          {
            item_id: String(buyOffer.value?.id),
            item_name: String(buyOffer.value?.product || buyOffer.value?.name),
            index: gameCart?.length || 0,
            quantity: buyOffer.value?.qty ?? 1,
            price: buyOffer.value?.discount?.value || buyOffer.value?.price?.value || 0
          }
        ],
        gs_game: buyOffer.value?.gameKey,
        gs_route: buyOffer.value?.gaContext?.gs_route || String(utilsPlugin.getApp()?.$route.name),
        gs_section: buyOffer.value?.gaContext?.gs_section,
        gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account}`
      }
    )
  }
}

function continueBuy(method: `${PaymentMethod}`) {
  if (buyOffer.value) {
    switch (method) {
      case PaymentMethod.CART:
        addToCart()
        break
      case PaymentMethod.GCOIN:
        buyGCoins(buyOffer.value)
        break
      case PaymentMethod.CASH:
        showCheckout('paymentMethod_cash_', false)
        break
      case PaymentMethod.CART_CHECKOUT:
      default:
        visibilityPaymentMethod.value = false
        openCartDrawer(buyOffer.value?.gameKey, {
          gs_game: buyOffer.value?.gaContext?.gs_game,
          gs_route: buyOffer.value?.gaContext?.gs_route,
          gs_section: `${buyOffer.value?.gaContext?.gs_section},${gaUtils.gaSections.paymentDialog},${gaUtils.gaSections.cart}`
        })
        buyOffer.value = null
    }
  } else {
    showCheckout('paymentMethod_', true)
  }
}

function beforeOpen() {
  selectedMethod.value = ''
  if (buyOffer.value?.price?.value) {
    selectedMethod.value = PaymentMethod.CASH
  } else if (isAvailableGcoinMethod.value) {
    selectedMethod.value = PaymentMethod.GCOIN
  }
}
</script>

<template>
  <el-dialog
    v-model="visibilityPaymentMethod"
    align-center
    modal-class="bg-black/80"
    class="gs-login-dialog rounded-2xl w-11/12 bg-gs-body-bg text-center overflow-hidden big:max-w-3xl"
    destroy-on-close
    :fullscreen="isMobileDevice"
    :show-close="false"
    @open="beforeOpen"
    @closed="onClosed"
  >
    <template #header>
      <div class="text-right pt-3 pr-5">
        <el-button
          size="large"
          class="text-xl stroke-2"
          circle
          @click="visibilityPaymentMethod = false"
        >
          <template #icon>
            <icon-ify
              icon="ep:close-bold"
              class="inline"
            />
          </template>
        </el-button>
      </div>
    </template>
    <template #default>
      <div class="bg-gs-body-bg py-1 px-5 big:px-10">
        <div class="text-2xl font-extrabold my-5">
          Payment method
        </div>
        <div class="max-w-[330px] mx-auto text-black font-semibold text-base">
          <div
            v-if="buyOffer?.price?.value"
            class="border py-3 px-4 min-h-[64px] rounded-lg mt-2.5 cursor-pointer opacity-95 hover:opacity-100 hover:shadow-md flex justify-between items-center"
            :class="{ 'bg-neutral-300 shadow-md': selectedMethod === PaymentMethod.CASH }"
            @click="selectedMethod = PaymentMethod.CASH"
          >
            <div>
              <img
                alt="cash"
                :src="CardUrl"
                class="w-8 inline-block mr-1.5"
              >
              Cash
            </div>
            <img
              v-if="selectedMethod === PaymentMethod.CASH"
              alt="checked"
              :src="CheckdUrl"
              class="w-8"
            >
          </div>
          <div
            v-if="isVisibleGcoinMethod"
            class="border py-3 px-4 min-h-[64px] rounded-lg mt-2.5 opacity-95  flex justify-between items-center relative group"
            :class="{
              'bg-neutral-300 shadow-md': selectedMethod === PaymentMethod.GCOIN,
              'bg-transparent cursor-not-allowed': !isAvailableGcoinMethod,
              'cursor-pointer hover:opacity-100 hover:shadow-md': isAvailableGcoinMethod
            }"
            @click="selectedMethod = isAvailableGcoinMethod ? PaymentMethod.GCOIN : selectedMethod"
          >
            <div>
              <img
                alt="gcoin"
                :src="GcoinUrl"
                class="w-8 inline-block mr-1.5"
              >
              GCoins
            </div>
            <img
              v-if="selectedMethod === PaymentMethod.GCOIN"
              alt="checked"
              :src="CheckdUrl"
              class="w-8"
            >
            <div
              v-if="!isAvailableGcoinMethod"
              class="text-gs-bg group-hover:text-red-500 text-right px-2 absolute bottom-0.5 left-0 right-0 text-xs"
            >
              You don't have enough GCoin's
            </div>
          </div>
        </div>
        <template v-if="gameSettings.enableCart && !buyOffer?.moGroupId">
          <el-divider class="my-10" />
          <div class="flex flex-col justify-center items-center min-h-14 mb-16 mt-5 gap-y-3 max-w-[330px] mx-auto">
            <el-button
              v-if="selectedMethod === PaymentMethod.GCOIN && isAvailableGcoinMethod"
              color="#3ba6bc"
              class="text-white rounded-lg uppercase text-base"
              size="large"
              style="--el-button-size: 50px"
              :loading="loading === `paymentMethod_${buyOffer?.gameKey}_${buyOffer?.id}`"
              @click="continueBuy(PaymentMethod.GCOIN)"
            >
              Continue
            </el-button>
            <template v-if="selectedMethod === PaymentMethod.CASH">
              <div class="w-full">
                <el-button
                  :disabled="!canAddToCart"
                  color="#3ba6bc"
                  class="text-white rounded-lg uppercase text-base w-full relative"
                  size="large"
                  style="--el-button-size: 50px"
                  :loading="loading === `paymentMethod_cart_${buyOffer?.gameKey}_${buyOffer?.id}`"
                  @click="continueBuy(PaymentMethod.CART)"
                >
                  <icon-ify
                    icon="tabler:shopping-cart-plus"
                    class="absolute left-2 big:left-4 text-2xl"
                  />
                  Add to cart
                </el-button>
                <template v-if="!canAddToCart">
                  <div
                    v-if="gameCart.length >= $store?.getters['game/cartCapacity']"
                    class="text-left text-xs -mb-2"
                  >
                    Cart is full (the maximum number of items: <b>{{ $store?.getters['game/cartCapacity'] }}</b>).
                  </div>
                  <div
                    v-else
                    class="text-left text-xs -mb-2"
                  >
                    Already added to cart ( purchase qty limit is:
                    <b>{{ buyOffer?.allowQty ? (buyOffer?.purchaseLimit === 1000 ? 1 : buyOffer?.purchaseLimit ?? 1) : 1 }}</b>
                    pcs ) .
                  </div>
                </template>
                <div
                  v-else-if="gameCartMap[buyOffer?.id ?? ''] !== undefined"
                  class="text-left text-xs -mb-2"
                >
                  Already added to cart: {{ gameCart?.[gameCartMap?.[buyOffer?.id ?? '']]?.qty }} pcs.
                </div>
              </div>
              <div class="w-full">
                <el-button
                  :disabled="
                    $store?.getters['game/gameCartMinVal'](buyOffer?.gameKey)
                      > (buyOffer?.discount?.value || buyOffer?.price?.value || 0)
                  "
                  color="#3ba6bc"
                  class="text-white rounded-lg uppercase text-base w-full ml-auto relative"
                  size="large"
                  style="--el-button-size: 50px"
                  :loading="loading === `paymentMethod_cash_${buyOffer?.gameKey}_${buyOffer?.id}`"
                  @click="continueBuy(PaymentMethod.CASH)"
                >
                  <icon-ify
                    icon="carbon:purchase"
                    class="absolute left-2 big:left-4 text-2xl"
                  />
                  Buy this offer
                </el-button>
                <div
                  v-if="
                    $store?.getters['game/gameCartMinVal'](buyOffer?.gameKey)
                      > (buyOffer?.discount?.value || buyOffer?.price?.value || 0)
                  "
                  class="text-left text-xs big:-mb-2"
                >
                  The minimum purchase amount is:
                  <b>{{ $store?.getters['game/loyalty'](buyOffer?.gameKey)?.minPurchaseCurHuman }}</b>.
                </div>
              </div>
              <div
                v-if="gameCart.length > 0"
                class="w-full"
              >
                <el-button
                  color="#3ba6bc"
                  class="text-white rounded-lg uppercase text-base w-full ml-auto relative leading-[1em]"
                  size="large"
                  style="--el-button-size: 50px"
                  :loading="loading === `paymentMethod_cart_checkout_${buyOffer?.gameKey}_${buyOffer?.id}`"
                  @click="continueBuy(PaymentMethod.CART_CHECKOUT)"
                >
                  <icon-ify
                    icon="tabler:shopping-cart-dollar"
                    class="absolute left-2 big:left-4 text-2xl"
                  />
                  <el-badge
                    :value="gameCart.length"
                    :show-zero="false"
                    class="cursor-pointer"
                    :offset="[15, 0]"
                    color="transparent"
                  >
                    <span>go to cart</span>
                  </el-badge>
                  <div class="absolute bottom-0 right-2 text-[0.7rem]">
                    {{ $store?.getters['game/gameCartSumValHuman'](buyOffer?.gameKey) }}
                  </div>
                </el-button>
                <div
                  v-if="!$store?.getters['game/gameCartPaymentAvailable'](buyOffer?.gameKey)"
                  class="text-left text-xs big:-mb-2"
                >
                  The minimum cart value is: <b>{{ $store.getters['game/loyalty'](buyOffer?.gameKey)?.minPurchaseCurHuman }}</b>.
                </div>
              </div>
            </template>
          </div>
        </template>
        <div
          v-else
          class="mt-10 mb-16"
        >
          <el-button
            v-if="selectedMethod"
            color="#3ba6bc"
            class="text-white rounded-lg uppercase text-base"
            size="large"
            style="--el-button-size: 50px"
            :loading="loading === `paymentMethod_${buyOffer?.gameKey}_${buyOffer?.id}`"
            @click="continueBuy(selectedMethod)"
          >
            Continue
          </el-button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="postcss">
.gs-login-dialog {
  --el-message-close-size: 1.5rem;
  --el-dialog-padding-primary: 0.1;
  .el-dialog {
    &__header {
      margin-right: 0 !important;
    }
  }
}
</style>
