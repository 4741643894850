<script setup lang="ts">
import { forgotPasswordMode, ForgotPasswordSteps } from '@/components/layout/LoginDialog/LoginDialog'
import { confirmPasswordValidator, onChangePassword, passwordValidator } from '@/compositions/validators'
import { ElNotification } from 'element-plus'

const changePasswordEmail = ref('')
const loadingChangePassword = ref(false)

function onSubmitForgotPasswordStep1(data: FormTypes.SubmitForm) {
  loadingChangePassword.value = true
  const { getGoatApiUrl, apiPost, catchFormErrors, GameKeyEnum, clearGameTokens } = gamesUtils
  clearGameTokens()
  ElNotification.closeAll()
  apiPost(GameKeyEnum.goat, getGoatApiUrl('reset-password'), { ...data.form }).then((response) => {
    changePasswordEmail.value = data.form?.email || ''
    switch (response?.data?.status || '') {
      case 'confirm_email':
        ElNotification({
          title: 'The verification code has been sent',
          duration: 9000,
          offset: 100,
          appendTo: '#auth-goat-wrapper',
          type: 'info',
          customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
        })
        forgotPasswordMode.value = ForgotPasswordSteps.step2
        break
      case 'user_not_found':
        ElNotification({
          title: 'The provided email has not been registered with us yet.',
          type: 'error',
          duration: 9000,
          offset: 100,
          appendTo: '#auth-goat-wrapper',
          customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
        })
        break
      default:
        ElNotification({
          title: 'Sending an email is currently unavailable',
          message: 'Contact us if the problem persists.',
          type: 'error',
          duration: 9000,
          offset: 100,
          appendTo: '#auth-goat-wrapper',
          customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
        })
    }
  }).catch(catchFormErrors(data.externalErrors, { appendTo: '#auth-goat-wrapper' })).then(() => {
    loadingChangePassword.value = false
  })
}
function onSubmitForgotPasswordStep2(data: FormTypes.SubmitForm) {
  loadingChangePassword.value = true
  const { getGoatApiUrl, apiPost, catchFormErrors, GameKeyEnum, clearGameTokens } = gamesUtils
  clearGameTokens()
  ElNotification.closeAll()
  apiPost(GameKeyEnum.goat, getGoatApiUrl('reset-password-confirm'), { ...data.form, email: changePasswordEmail.value }).then((response) => {
    switch (response?.data?.status || '') {
      case 'ok':
        ElNotification({
          title: 'The password has been changed',
          message: 'Now you can log in.',
          type: 'success',
          duration: 9000,
          offset: 100,
          appendTo: '#auth-goat-wrapper',
          customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
        })
        changePasswordEmail.value = ''
        forgotPasswordMode.value = ''
        break
      case 'user_not_found':
        ElNotification({
          title: 'The provided email has not been registered with us yet.',
          type: 'error',
          duration: 9000,
          offset: 100,
          appendTo: '#auth-goat-wrapper',
          customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
        })
        break
      case 'invalid_token':
        ElNotification({
          title: 'Invalid verification code',
          type: 'error',
          duration: 9000,
          offset: 100,
          appendTo: '#auth-goat-wrapper',
          customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
        })
        break
      default:
        ElNotification({
          title: 'Password change is unavailable.',
          message: 'Contact us if the problem persists.',
          type: 'error',
          duration: 9000,
          offset: 100,
          appendTo: '#auth-goat-wrapper',
          customClass: 'right-1/2 translate-x-1/2 large absolute max-w-full'
        })
    }
  }).catch(catchFormErrors(data.externalErrors, { appendTo: '#auth-goat-wrapper' })).then(() => {
    loadingChangePassword.value = false
  })
}
</script>

<template>
  <template v-if="forgotPasswordMode === ForgotPasswordSteps.step1">
    <GsFormComponent
      class="w-full text-white"
      size="large"
      hide-required-asterisk
      @submit="onSubmitForgotPasswordStep1"
    >
      <template #default="{ submit, form }">
        <GsFieldTextTrim
          v-focus
          api-field-name="email"
          placeholder="Email"
          :label="!!form?.email?.length"
          class="mt-1 big:mt-1.5 text-lg"
          required
          :rules="[{ type: 'email', trigger: 'blur', message: 'Not valid email' }]"
          @keyup.enter.stop.prevent="submit"
        />
        <GsFormFieldsGroup>
          <div class="mb-6 px-2 text-base">
            A verification code will be sent to the provided email address, which must be entered in the next step.
          </div>
        </GsFormFieldsGroup>
        <GsFormFieldsGroup
          justify="space-between"
        >
          <el-button
            type="info"
            class="text-neutral-500 rounded-lg uppercase text-lg w-[46%] font-semibold"
            @click="forgotPasswordMode = ''"
          >
            cancel
          </el-button>
          <el-button
            type="primary"
            class="text-white rounded-lg uppercase text-lg w-[46%] font-semibold gs-loading"
            size="large"
            :loading="loadingChangePassword"
            @click="submit()"
          >
            send email
          </el-button>
        </GsFormFieldsGroup>
      </template>
    </GsFormComponent>
  </template>
  <template v-else-if="forgotPasswordMode === ForgotPasswordSteps.step2">
    <h1 class="text-lg uppercase font-semibold text-center tracking-wider text-white ">
      Change password
    </h1>
    <GsFormComponent
      class="w-full"
      size="large"
      hide-required-asterisk
      @submit="onSubmitForgotPasswordStep2"
    >
      <template #default="{ submit, form, formRefer }">
        <GsFieldTextTrim
          api-field-name="password"
          placeholder="Password"
          :label="!!form?.password?.length"
          class="mt-1 big:mt-1.5 text-lg"
          type="password"
          show-password
          :rules="[passwordValidator()]"
          @input="onChangePassword(form, formRefer)"
          @keyup.enter.stop.prevent="submit"
        />
        <GsFieldTextTrim
          api-field-name="confirmPassword"
          placeholder="Confirm password"
          :label="!!form?.confirmPassword?.length"
          class="mt-1 big:mt-1.5 text-lg"
          type="password"
          show-password
          :rules="[confirmPasswordValidator(form)]"
          @keyup.enter.stop.prevent="submit"
        />
        <GsFieldTextTrim
          api-field-name="token"
          placeholder="Verification code"
          :label="!!form?.token?.length ? 'Verification code' : false"
          class="mt-1 big:mt-1.5 text-lg"
          required
          maxlength="4"
          show-word-limit
          :rules="[{ type: 'string', trigger: 'change', len: 4, message: '4 characters required' }]"
          @keyup.enter.stop.prevent="submit"
        />
        <GsFormFieldsGroup
          justify="space-between"
        >
          <el-button
            type="info"
            class="text-neutral-500 rounded-lg uppercase text-lg w-[46%] font-semibold gs-loading"
            @click="forgotPasswordMode = ''"
          >
            cancel
          </el-button>
          <el-button
            type="primary"
            class="text-white rounded-lg uppercase text-lg w-[46%] font-semibold gs-loading"
            size="large"
            :loading="loadingChangePassword"
            @click="submit()"
          >
            confirm
          </el-button>
        </GsFormFieldsGroup>
      </template>
    </GsFormComponent>
  </template>
</template>

<style scoped lang="postcss">

</style>
